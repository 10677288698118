import {
  getRankList,
  getGameDetails,
  getTopTenAnchor,
  getAllMetrics,
  getTopRank
} from '../services/gameList'
import { postFollowed, deleteFollowed, getStreamerList } from '../services/streamers'
import { getDeveloper } from '../services/developer'
import _ from 'lodash'
import { message } from 'antd'

export default {
  namespace: 'gameList',
  state: {
    pageLoading: true,
    list: null,
    toplist: null,
    metricslist: null,
    page: 1,
    perPage: 20,
    followedStatus: false,
    developerList: [],
    gameDetails: null,
    flag: false,
    topTenAnchor: [],
    tableLoading: false,
    gameRankList: null,
    followedFlag: false,
  },
  effects: {
    //获取游戏榜单
    *apiGetRankList({ payload }, { call, put }){
      yield put({ type: 'setState', payload: {followedStatus: false, pageLoading: true}})
      const response = yield call(getRankList, payload)
      yield put({ type: 'setState', payload: {pageLoading: false, list: response, page: response.page, perPage: response.perPage}})
    },
    *apiPostFollowed({payload}, {call, put}){
      yield call(postFollowed, payload)
      message.success('Subscribed!')
      yield put({ type: 'setState', payload: {followedStatus: true} })
    },
    *apiDeleteFollowed({payload}, {call, put}){
      yield call(deleteFollowed, payload)
      message.success('Unfollow successfully!')
      yield put({ type: 'setState', payload: {followedStatus: true} })
    },
    *apiGetDeveloper({payload},{call, put}){
      let response = yield call(getDeveloper, payload)
      yield put({ type: 'setState', payload: { developerList: response }})
    },
    //获取游戏详情
    *apiGetGameDetails({ payload }, { call, put }){
      const response = yield call(getGameDetails, payload)
      yield put({ type: 'setGameDetails', payload: response })
    },
    //收藏
    *apiPostCollectionGame({ payload }, { call, put }){
      yield call(postFollowed, payload)
      yield put({ type: 'setCollection', payload: { flag: 1} })
    },
    //取消收藏
    *apiDeleteCollectionGame({ payload }, { call, put }){
      yield call(deleteFollowed, payload)
      yield put({ type: 'setCollection', payload: { flag: 2 } })
    },
    //获取十大主播
    *apiGetTopTenAnchor({ payload }, { call, put }){
      const response = yield call(getTopTenAnchor, payload)
      yield put({ type: 'setState', payload: { topTenAnchor: response } })
    },

    //获取游戏主播排行
    *apiGetGameStreamers({payload},{call, put}){
      yield put({ type: 'setState', payload: {tableLoading: true}})
      let response = yield call(getStreamerList, payload)
      yield put({type: 'setState', payload: {tableLoading: false, gameRankList: response}})
    },
    *apiPostFollowedStreamers({payload}, {call, put}){
      yield call(postFollowed, payload)
      message.success('Subscribed!')
      yield put({ type: 'setState', payload: {followedFlag: true} })
    },
    *apiDeleteFollowedStreamers({payload}, {call, put}){
      yield call(deleteFollowed, payload)
      message.success('Unfollow successfully!')
      yield put({ type: 'setState', payload: {followedFlag: true} })
    },
    //获取TOP
    *apiGetTop({ payload }, { call, put }){
      yield put({ type: 'setState', payload: {followedStatus: false, pageLoading: true}})
      const response = yield call(getTopRank, payload)
      yield put({ type: 'setState', payload: {pageLoading: false, toplist: response, page: response.page, perPage: response.perPage}})
    },
    //获取TOP
    *apiGetAllMetrics({ payload }, { call, put }){
      yield put({ type: 'setState', payload: {followedStatus: false, pageLoading: true}})
      const response = yield call(getAllMetrics, payload)
      yield put({ type: 'setState', payload: {pageLoading: false, metricslist: response, page: response.page, perPage: response.perPage}})
    },
  },
  reducers: {
    setState(state, { payload }){
      return {
        ...state,
        ...payload
      }
    },
    setList(state, { payload }){
      return {
        ...state,
        list: payload,
        page: payload.page,
        perPage: payload.perPage
      }
    },
    setGameDetails(state, { payload }){
      if(payload.datas){
        payload.datas = _.orderBy(payload.datas, 'date', 'asc')
      }
      return {
        ...state,
        gameDetails: payload
      }
    },
    setCollection(state, { payload }){
      let { gameDetails } = state
      if(payload.flag === 1){
        gameDetails.follwed = true
      }else{
        gameDetails.follwed = false
      }
      return {
        ...state,
        gameDetails: _.cloneDeep(gameDetails),
        flag: payload.flag
      }
    }
  }
}