import React, { Component } from 'react'
import { connect } from 'dva'
import { Table, Row, Col, Tooltip, Space, DatePicker } from 'antd'
import { CaretDownOutlined, ExportOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import './styles.less'
import moment from 'moment'
import Config from '../../config/Config'
import { getToken } from '../../utils/authority'
import qs from 'query-string'

import BasicLayout from '../../components/BasicLayout'
import { routerRedux } from 'dva/router'
import { last, round, set } from 'lodash'
var clock = moment().locale('zh-cn').format('HH');


class GameList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dateVO: {
        current: moment().subtract((parseInt(clock) < 13) ? 2 : 1, 'days').format('YYYY-MM-DD'),
        last: moment().subtract((parseInt(clock) < 13) ? 3 : 2, 'days').format('YYYY-MM-DD')
      },
      catalog: [
        {
          nameEN: 'Streaming Platform',
          nameCN: '流媒体网站',
          list: [
            { nameEN: 'All', value: null },
            { nameEN: 'DouYu', value: '103' },
            { nameEN: 'HuYa', value: '102' },
            { nameEN: 'Bilibili', value: '101' },
          ]
        },
        {
          nameEN: 'Game Publisher',
          nameCN: '经营者',
          list: [
            { nameEN: 'All', value: null },
            { nameEN: 'Tencent', value: 'Tencent' },
            { nameEN: 'NetEase', value: 'Netease' },
            { nameEN: 'Steam', value: 'Steam' },
            {nameEN: 'Mihoyo Game', value: 'Mihoyo Game'},
            {nameEN: 'EA', value: 'EA'},
            {nameEN: 'Ubisoft', value: 'Ubisoft'},
            {nameEN: 'Perfect World', value: 'Perfect World'},
            {nameEN: 'Nexon', value: 'Nexon'},
            {nameEN: 'Blizzard', value: 'Blizzard'},
            {nameEN: 'SCE', value: 'SCE'},
            {nameEN: 'Nintendo', value: 'Nintendo'},
            {nameEN: 'Shengqu', value: 'Shengqu'},
            {nameEN: '37 Game', value: '37 Game'},
            {nameEN: '4399 Game', value: '4399 Game'},
            {nameEN: 'Leiting Game', value: 'Leiting Game'},
            {nameEN: 'Activison', value: 'Activison'},
            {nameEN: 'Take-Two', value: 'Take-Two'},
            {nameEN: 'Bilibili', value: 'Bilibili'},
            { nameEN: 'Other', value: 'other' },
          ]
        },
        {
          nameEN: 'Game Segment',
          nameCN: '平台',
          list: [
            { nameEN: 'All', value: null },
            { nameEN: 'PC', value: 'PC' },
            { nameEN: 'Mobile', value: 'Mobile' },
            { nameEN: 'Console', value: 'Console' },
            // {nameEN: 'Non-game', value: 'Non-game'}
          ]
        },
        {
          nameEN: 'Genre',
          nameCN: '类型',
          list: [
            { nameEN: 'All', value: null },
            { nameEN: 'MOBA', value: 'MOBA' },
            { nameEN: 'Battle Royale', value: 'Battle Royale' },
            { nameEN: 'RPG', value: 'RPG' },
            // {nameEN: 'Non-game', value: 'Non-game'},
            { nameEN: 'FPS/TPS', value: 'FPS/TPS' },
            { nameEN: 'Auto Chess', value: 'Auto Chess' },
            // {nameEN: 'Series games', value: 'Series games'},
            { nameEN: 'Racing', value: 'Racing' },
            { nameEN: 'Sandbox', value: 'Sandbox' },
            { nameEN: 'Trading Card', value: 'Trading Card' },
            { nameEN: 'Strategy', value: 'Strategy' },
            { nameEN: 'RTS', value: 'RTS' },
            { nameEN: 'Action', value: 'Action' },
            { nameEN: 'Sporting', value: 'Sporting' },
            { nameEN: 'Horror', value: 'Horror game' },
            { nameEN: 'Puzzle', value: 'Puzzle' },
            { nameEN: 'Simulation', value: 'Simulation' },
            { nameEN: 'Adventure', value: 'Adventure' },
            { nameEN: 'Poker & Mahjong', value: 'Poker & Mahjong' },
            { nameEN: 'Running', value: 'Running' },
            { nameEN: 'Casual', value: 'Casual' },
            { nameEN: 'Idle', value: 'Idle' },
            { nameEN: 'Dancing', value: 'Dancing' },
            { nameEN: 'Roguelike', value: 'Roguelike' },
          ]
        },
        {
          nameEN: 'Developers',
          nameCN: '发展商',
          list: [
            {nameEN: 'All', value: null},
            {nameEN: 'Tencent', value: 615},
            {nameEN: 'NetEase', value: 617},
            {nameEN: 'Steam', value: 616},
            {nameEN: 'Mihoyo Game', value: 627},
            {nameEN: 'EA', value: 622},
            {nameEN: 'Ubisoft', value: 690},
            {nameEN: 'Perfect World', value: 618},
            {nameEN: 'Nexon', value: 766},
            {nameEN: 'Blizzard', value: 621},
            {nameEN: 'SCE', value: 653},
            {nameEN: 'Nintendo', value: 647},
            {nameEN: 'Shengqu', value: 623},
            {nameEN: '37 Game', value: 686},
            {nameEN: '4399 Game', value: 752},
            {nameEN: 'Leiting Game', value: 691},
            {nameEN: 'Activison', value: 1065},
            {nameEN: 'Take-Two', value: 790},
            {nameEN: 'Bilibili', value: 631},
            {nameEN: 'Other', value: 'other'},
          ]
        },
        {
          nameEN: 'Time Period',
          nameCN: '时间段',
          list: [
            { nameEN: 'Day', nameCN: '昨天', value: '0' },
            { nameEN: 'Week', nameCN: '周', value: '1' },
            { nameEN: 'Month', nameCN: '月度', value: '2' },
            { nameEN: 'Quarter', nameCN: '季度', value: '3' },
            { nameEN: 'Year', nameCN: '年度', value: '4' }
          ]
        },
        {
          nameEN: 'Metrics',
          nameCN: '维度',
          list: [
            { nameEN: 'Streamers', nameCN: '主播数量', value: 'sumAnchor' },
            { nameEN: 'Hours Streamed', nameCN: '主播时长', value: 'sumLenth' },
            { nameEN: 'Subscribers', nameCN: '游戏粉丝数', value: 'sumFans' },
            { nameEN: 'Tips', nameCN: '游戏打赏收入', value: 'sumGift' },
            { nameEN: 'Heat Index', nameCN: '游戏热力值', value: 'sumHot' },
            { nameEN: 'Niko Index', nameCN: 'NIKO指数', value: 'sumNiko' }
          ]
        },
      ],
      catalogSelected: [
        { value: [] },
        { value: [] },
        { value: [] },
        { value: [] },
        { value: [] },
        { value: '0' },
        { value: 'sumAnchor' }
      ],
      search: {
        cate: 'sumAnchor',
        dateType: 0,
      },
      dateIndex: 0,
      showMore: false,
      catelist: {
        sumAnchor: { lastCate: "lastSumAnchor", nameEN: 'Streamers', nameCN: '主播数量', cate: 'sumAnchor', tips: 'This measures the total cumulative number of streamers for the game or category on a daily cumulative basis for the time period selected.', list: [] },
        sumLenth: { lastCate: "lastSumLenth", nameEN: 'Streamed Hours', nameCN: '主播时长', cate: 'sumLenth', tips: 'This measures the cumulative number of hours streamed for the game, category or individual streamer across the time period selected.', list: [] },
        sumGift: { lastCate: "lastSumGift", nameEN: 'Tips', nameCN: '游戏打赏收入', cate: 'sumGift', tips: 'This measures the cumulative value of tips and virtual gifts that streamers receive within the game, category or as an individual streamer. This is measured in RMB and converted to USD.', list: [] },
        sumFans: { lastCate: "lastSumFans", nameEN: 'Subscribers', nameCN: '游戏粉丝数', cate: 'sumFans', tips: 'For Games, this measures the daily average number of subscribers that are subscribed to streamers of a game or category during the selected time period. For streamers this measures the total number of subscribers for the streamers channel.', list: [] },
        sumHot: { lastCate: "lastSumHot", nameEN: 'Heat Index', nameCN: '游戏热力值', cate: 'sumHot', tips: 'An index based on the daily average value of three streaming platforms’ own algorithms, providing a rating of the games number of viewers, hours streamed, and number of virtual gifts or tips donated.', list: [] },
        sumNiko: { lastCate: "lastSumNiko", nameEN: 'Niko Index', nameCN: 'NIKO指数', cate: 'sumNiko', tips: 'A weighted index estimated by Niko based on an algorithm approximating the number of active viewers of each streamer, game or category. This is a cumulative figure for the period selected.', list: [] }
      },
      dateString: null,
    }
  }

  componentDidMount() {
    const { search } = this.state
    this.props.dispatch({
      type: 'gameList/apiGetTop',
      payload: {
        page: 1,
        ...search
      }
    })
  }

  componentWillReceiveProps(nextProps, nextContext) {
  }

  async componentDidUpdate(prevProps, prevState) {
    const { perPage } = this.props
    let { catalogSelected, dateVO, dateString } = this.state

    if (this.state.catalogSelected !== prevState.catalogSelected) {
      let payload = {}
      if ((this.state.dateIndex !== prevState.dateIndex) && dateString) {
        payload.date = await this.handleDateChange(catalogSelected[5].value)
        console.log(payload.date, dateString, 1);
        this.setState({ dateString: null })
        dateString = null
      } else if (!dateString) {
        payload.date = await this.handleDateChange(catalogSelected[5].value)
        console.log(payload.date, dateString, 2);
      } else {
        payload.date = dateString
        console.log(payload.date, dateString, 3);
      }
      payload.platformID = catalogSelected[0].value.length !== 0 ? catalogSelected[0].value.map((vo) => { return vo }).join(',') : null
      payload.Operator = catalogSelected[1].value.length !== 0 ? catalogSelected[1].value.map((vo) => { return vo }).join(',') : null
      payload.Platform = catalogSelected[2].value.length !== 0 ? catalogSelected[2].value.map((vo) => { return vo }).join(',') : null
      payload.gameGenre = catalogSelected[3].value.length !== 0 ? catalogSelected[3].value.map((vo) => { return vo }).join(',') : null
      payload.DeveloperID = catalogSelected[4].value.length !== 0 ? catalogSelected[4].value.map((vo) => { return vo }).join(',') : null
      payload.dateType = catalogSelected[5].value
      payload.cate = catalogSelected[6].value
      let datecount = parseInt(catalogSelected[5].value)
      switch (datecount) {
        case 0:
          dateVO.current = moment(payload.date).subtract(1, 'days').format('YYYY-MM-DD')
          dateVO.last = moment(payload.date).subtract(2, 'days').format('YYYY-MM-DD')
          break;
        case 1:
          // 上周周初
          const lastWeek = moment().year(payload.date.slice(0, 4)).week(payload.date.slice(5, 7)).subtract(1, 'weeks').startOf('isoWeek');
          // 获取该周的起始日期（周一）
          const startOfWeek = moment().year(payload.date.slice(0, 4)).week(payload.date.slice(5, 7)).startOf('isoWeek');
          // 获取该周的结束日期（周日）
          const endOfWeek = moment(startOfWeek).endOf('isoWeek');
          dateVO.current = `${startOfWeek.subtract(1, 'days').format('YYYY-MM-DD')}~${endOfWeek.subtract(1, 'days').format('YYYY-MM-DD')}`;
          dateVO.last = `${lastWeek.subtract(1, 'days').format('YYYY-MM-DD')}~${startOfWeek.subtract(1, 'day').format('YYYY-MM-DD')}`;
          break;
        case 2:
          dateVO.current = moment(payload.date).format('YYYY-MM')
          dateVO.last = moment(payload.date).subtract(1, 'months').format('YYYY-MM')
          break;
        case 3:
          dateVO.current = payload.date
          const dateStr1 = payload.date;
          const lastDigit1 = parseInt(dateStr1[6]) || 0;
          const newDigit1 = lastDigit1 > 1 ? lastDigit1 - 1 : 4;
          const newDateStr1 = lastDigit1 === 1 ? ((parseInt(dateStr1.slice(0, 4)) - 1) + '-Q' + newDigit1) : dateStr1.slice(0, 6) + newDigit1;
          dateVO.last = newDateStr1
          break;
        case 4:
          dateVO.current = moment(payload.date).format('YYYY')
          dateVO.last = moment(payload.date).subtract(1, 'years').format('YYYY')
          break;
        default:
          break;
      }
      this.setState({ dateVO: dateVO, search: payload, dateIndex: parseInt(payload.dateType) })
      this.props.dispatch({
        type: 'gameList/apiGetTop',
        payload: { page: 1, perPage, ...payload }
      })
    }
  }
  handleDateChange = async (dateIndex) => {
    dateIndex = parseInt(dateIndex)
    switch (dateIndex) {
      case 0:
        return moment().format('YYYY-MM-DD')
      case 1:
        return moment().format('YYYY-WW')
      case 2:
        return moment().format('YYYY-MM')
      case 3:
        return moment().format('YYYY-') + "Q" + moment().format('Q')
      case 4:
        return moment().format('YYYY')
      default:
        break;
    }
  }

  handleSelectCatalog = (item, index) => {
    const { catalogSelected, dateString } = this.state
    if (index === 5 && dateString) {
      this.setState({ dateString: null })
    }
    if (index === 5 || index === catalogSelected.length - 1) {
      if (index === 2 && item === 'other') {
        this.props.dispatch(routerRedux.push('/company'))
        return
      }
      if (catalogSelected[index].value === item) {
        catalogSelected[index].value = null
        return
      }
      catalogSelected[index].value = item
    } else {
      if (index === 5 && item === 'other') {
        this.props.dispatch(routerRedux.push('/developer'))
        return
      }
      let key = catalogSelected[index].value.indexOf(item)
      if (key < 0) {
        if (!item) {
          catalogSelected[index].value = []
        } else {
          catalogSelected[index].value.push(item)
        }
      } else {
        catalogSelected[index].value.splice(key, 1)
      }
    }
    this.setState({ catalogSelected: catalogSelected.concat() })
  }

  handleJumpToDetails = (id) => {
    if (id) {
      this.props.dispatch(routerRedux.push(`/gameList/gameDetails/${id}`))
    }
  }
  disabledDate(current) {
    if (current) {
      return current > moment().endOf('day') || current < moment('2020-07').endOf('day');
    }
  }
  onChange = (date, dateString) => {
    let { search, dateVO } = this.state
    const { perPage } = this.props
    this.props.dispatch({
      type: 'gameList/apiGetTop',
      payload: { page: 1, perPage, ...search, date: dateString }
    })
    switch (parseInt(search.dateType)) {
      case 0:
        dateVO.current = dateString
        dateVO.last = moment(dateString).subtract(1, 'days').format('YYYY-MM-DD')
        break;
      case 1:
        // 上周周初
        const lastWeek = moment().year(dateString.slice(0, 4)).week(dateString.slice(5, 7)).subtract(1, 'weeks').startOf('isoWeek');
        // 获取该周的起始日期（周一）
        const startOfWeek = moment().year(dateString.slice(0, 4)).week(dateString.slice(5, 7)).startOf('isoWeek');
        // 获取该周的结束日期（周日）
        const endOfWeek = moment(startOfWeek).endOf('isoWeek');
        dateVO.current = `${startOfWeek.subtract(1, 'days').format('YYYY-MM-DD')}~${endOfWeek.subtract(1, 'days').format('YYYY-MM-DD')}`;
        dateVO.last = `${lastWeek.subtract(1, 'days').format('YYYY-MM-DD')}~${startOfWeek.subtract(1, 'day').format('YYYY-MM-DD')}`;
        break;
      case 2:
        dateVO.current = dateString
        dateVO.last = moment(dateString).subtract(1, 'months').format('YYYY-MM')
        break;
      case 3:
        dateVO.current = dateString
        const dateStr1 = dateString
        const lastDigit1 = parseInt(dateStr1[6]) || 0;
        const newDigit1 = lastDigit1 > 1 ? lastDigit1 - 1 : 4;
        const newDateStr1 = lastDigit1 === 1 ? ((parseInt(dateStr1.slice(0, 4)) - 1) + '-Q' + newDigit1) : dateStr1.slice(0, 6) + newDigit1;
        dateVO.last = newDateStr1
        break;
      case 4:
        dateVO.current = moment(dateString).format('YYYY')
        dateVO.last = moment(dateString).subtract(1, 'years').format('YYYY')
        break;
      default:
        break;
    }
    this.setState({
      search: { ...search, date: dateString },
      dateVO: dateVO,
      dateString
    })
  };
  renderDate = () => {
    let { dateIndex, search } = this.state
    switch (dateIndex) {
      case 0:
        return (
          <div className="dateblow">Date: <DatePicker value={search && search.date ? moment(search.date) : null} disabledDate={this.disabledDate} onChange={this.onChange} /></div>
        )
      case 1:
        return (
          <div className="dateblow">Date: <DatePicker disabledDate={this.disabledDate} onChange={this.onChange} picker="week" /></div>
        )
      case 2:
        return (
          <div className="dateblow">Date: <DatePicker value={search && search.date ? moment(search.date) : null} disabledDate={this.disabledDate} onChange={this.onChange} picker="month" /></div>
        )
      case 3:
        return (
          <div className="dateblow">Date: <DatePicker disabledDate={this.disabledDate} onChange={this.onChange} picker="quarter" /></div>
        )
      case 4:
        return (
          <div className="dateblow">Date: <DatePicker value={search && search.date ? moment(search.date) : null} disabledDate={this.disabledDate} onChange={this.onChange} picker="year" /></div>
        )
      default:
        break;
    }

  }
  renderTable = () => {
    const { search, catelist } = this.state
    const { toplist, page, perPage, locale, pageLoading } = this.props
    const pagination = {
      current: parseInt(page) || 0,
      total: (toplist || {}).total || 0,
      defaultCurrent: 1,
      defaultPageSize: parseInt(perPage) || 0,
      pageSize: perPage,
      showQuickJumper: true,
      position: 'bottom',
    }
    const columns = [
      {
        title: <div className={'cate_header no_cursor'}>{locale === 'EN' ? 'Game' : '游戏'}</div>,
        key: 'gameInfo',
        width: '20%',
        render: (record, row, index) => {
          return (
            <div className="inline" onClick={() => { this.handleJumpToDetails(record.gameID) }}>
              <div className="logo">
                {record.gameInfo && record.gameInfo.link ? <img alt={'img'} src={record.gameInfo.link} /> : null}
              </div>
              {record.gameInfo ? (<Space direction='vertical'>
                <div className="name">{record.gameInfo.gameEn}</div>
                <div className="nameCn" style={{ color: '#F4791F' }}>{record.gameInfo.game}</div>
              </Space>) : null}
            </div>
          )
        }
      },
      ...(search.cate === catelist.sumAnchor.cate ? [{
        title: <div id={'header'} className={`cate_header no_cursor`}>
          <div className="title_name">
            <Tooltip overlayClassName="tooltip" className="text_icon" title={catelist.sumAnchor.tips} >
              <QuestionCircleOutlined />
            </Tooltip>
            {locale === 'EN' ? `${catelist.sumAnchor.nameEN}(Current)` : catelist.sumAnchor.nameCN}
          </div>
        </div>,
        key: catelist.sumAnchor.cate,
        dataIndex: catelist.sumAnchor.cate,
        width: '13%',
        align: 'right',
        render: (record, row, index) => {
          return (
            <div className="custom_txt">{(record && record.toLocaleString())}</div>
          )
        }
      }, {
        title: <div id={'header'} className={`cate_header no_cursor`}>
          <div className="title_name">
            <Tooltip overlayClassName="tooltip" className="text_icon" title={catelist.sumAnchor.tips} >
              <QuestionCircleOutlined />
            </Tooltip>
            {locale === 'EN' ? `${catelist.sumAnchor.nameEN}(Prior)` : catelist.sumAnchor.nameCN}
          </div>
        </div>,
        key: catelist[search.cate].lastCate,
        dataIndex: catelist[search.cate].lastCate,
        width: '13%',
        align: 'right',
        render: (record, row, index) => {
          return (
            <div className="custom_txt">{(row && row.lastData.length &&
              row.lastData[0][catelist[search.cate].lastCate]
              && row.lastData[0][catelist[search.cate].lastCate].toLocaleString()) ||
              <div style={{ color: 'green' }}>{'-New-'}</div>}</div>
          )
        }
      },] : []),
      ...(search.cate === catelist.sumLenth.cate ? [{
        title: <div id={'header'} className={`cate_header no_cursor`}>
          <div className="title_name">
            <Tooltip overlayClassName="tooltip" className="text_icon" title={catelist.sumLenth.tips} >
              <QuestionCircleOutlined />
            </Tooltip>
            {locale === 'EN' ? `${catelist.sumLenth.nameEN}(Current)` : catelist.sumLenth.nameCN}
          </div>
        </div>,
        key: catelist.sumLenth.cate,
        dataIndex: catelist.sumLenth.cate,
        width: '13%',
        align: 'right',
        render: (record, row, index) => {
          return (
            <div className="custom_txt">{(record && record.toLocaleString())}</div>
          )
        }
      }, {
        title: <div id={'header'} className={`cate_header no_cursor`}>
          <div className="title_name">
            <Tooltip overlayClassName="tooltip" className="text_icon" title={catelist.sumLenth.tips} >
              <QuestionCircleOutlined />
            </Tooltip>
            {locale === 'EN' ? `${catelist.sumLenth.nameEN}(Prior)` : catelist.sumLenth.nameCN}
          </div>
        </div>,
        key: catelist.sumLenth.lastCate,
        dataIndex: catelist.sumLenth.lastCate,
        width: '13%',
        align: 'right',
        render: (record, row, index) => {
          return (
            <div className="custom_txt">{(row && row.lastData.length && row.lastData[0][catelist.sumLenth.lastCate] && row.lastData[0][catelist.sumLenth.lastCate].toLocaleString()) ||
              <div style={{ color: 'green' }}>{'-New-'}</div>}</div>
          )
        }
      },] : []),
      ...(search.cate === catelist.sumGift.cate ? [{
        title: <div id={'header'} className={`cate_header no_cursor`}>
          <div className="title_name">
            <Tooltip overlayClassName="tooltip" className="text_icon" title={catelist.sumGift.tips} >
              <QuestionCircleOutlined />
            </Tooltip>
            {locale === 'EN' ? `${catelist.sumGift.nameEN}(Current)` : catelist.sumGift.nameCN}
          </div>
        </div>,
        key: catelist.sumGift.cate,
        dataIndex: catelist.sumGift.cate,
        width: '13%',
        align: 'right',
        render: (record, row, index) => {
          return (
            <div className="custom_txt">{(record && record.toLocaleString())}</div>
          )
        }
      }, {
        title: <div id={'header'} className={`cate_header no_cursor`}>
          <div className="title_name">
            <Tooltip overlayClassName="tooltip" className="text_icon" title={catelist.sumGift.tips} >
              <QuestionCircleOutlined />
            </Tooltip>
            {locale === 'EN' ? `${catelist.sumGift.nameEN}(Prior)` : catelist.sumGift.nameCN}
          </div>
        </div>,
        key: catelist.sumGift.lastCate,
        dataIndex: catelist.sumGift.lastCate,
        width: '13%',
        align: 'right',
        render: (record, row, index) => {
          return (
            <div className="custom_txt">
              {(row && row.lastData.length && row.lastData[0][catelist.sumGift.lastCate] && row.lastData[0][catelist.sumGift.lastCate].toLocaleString()) ||
                <div style={{ color: 'green' }}>{'-New-'}</div>}
            </div>
          )
        }
      },] : []),
      ...(search.cate === catelist.sumFans.cate ? [{
        title: <div id={'header'} className={`cate_header no_cursor`}>
          <div className="title_name">
            <Tooltip overlayClassName="tooltip" className="text_icon" title={catelist.sumFans.tips} >
              <QuestionCircleOutlined />
            </Tooltip>
            {locale === 'EN' ? `${catelist.sumFans.nameEN}(Current)` : catelist.sumFans.nameCN}
          </div>
        </div>,
        key: catelist.sumFans.cate,
        dataIndex: catelist.sumFans.cate,
        width: '13%',
        align: 'right',
        render: (record, row, index) => {
          return (
            <div className="custom_txt">{(record && record.toLocaleString())}</div>
          )
        }
      }, {
        title: <div id={'header'} className={`cate_header no_cursor`}>
          <div className="title_name">
            <Tooltip overlayClassName="tooltip" className="text_icon" title={catelist.sumFans.tips} >
              <QuestionCircleOutlined />
            </Tooltip>
            {locale === 'EN' ? `${catelist.sumFans.nameEN}(Prior)` : catelist.sumFans.nameCN}
          </div>
        </div>,
        key: catelist.sumFans.lastCate,
        dataIndex: catelist.sumFans.lastCate,
        width: '13%',
        align: 'right',
        render: (record, row, index) => {
          return (
            <div className="custom_txt">
              {(row && row.lastData.length && row.lastData[0][catelist.sumFans.lastCate] && row.lastData[0][catelist.sumFans.lastCate].toLocaleString()) ||
                <div style={{ color: 'green' }}>{'-New-'}</div>}
            </div>
          )
        }
      },] : []),
      ...(search.cate === catelist.sumHot.cate ? [{
        title: <div id={'header'} className={`cate_header no_cursor`}>
          <div className="title_name">
            <Tooltip overlayClassName="tooltip" className="text_icon" title={catelist.sumHot.tips} >
              <QuestionCircleOutlined />
            </Tooltip>
            {locale === 'EN' ? `${catelist.sumHot.nameEN}(Current)` : catelist.sumHot.nameCN}
          </div>
        </div>,
        key: catelist.sumHot.cate,
        dataIndex: catelist.sumHot.cate,
        width: '13%',
        align: 'right',
        render: (record, row, index) => {
          return (
            <div className="custom_txt">{(record && record.toLocaleString())}</div>
          )
        }
      }, {
        title: <div id={'header'} className={`cate_header no_cursor`}>
          <div className="title_name">
            <Tooltip overlayClassName="tooltip" className="text_icon" title={catelist.sumHot.tips} >
              <QuestionCircleOutlined />
            </Tooltip>
            {locale === 'EN' ? `${catelist.sumHot.nameEN}(Prior)` : catelist.sumHot.nameCN}
          </div>
        </div>,
        key: catelist.sumHot.lastCate,
        dataIndex: catelist.sumHot.lastCate,
        width: '13%',
        align: 'right',
        render: (record, row, index) => {
          return (
            <div className="custom_txt">
              {(row && row.lastData.length && row.lastData[0][catelist.sumHot.lastCate] && row.lastData[0][catelist.sumHot.lastCate].toLocaleString()) ||
                <div style={{ color: 'green' }}>{'-New-'}</div>}
            </div>
          )
        }
      },] : []),
      ...(search.cate === catelist.sumNiko.cate ? [{
        title: <div id={'header'} className={`cate_header no_cursor`}>
          <div className="title_name">
            <Tooltip overlayClassName="tooltip" className="text_icon" title={catelist.sumNiko.tips} >
              <QuestionCircleOutlined />
            </Tooltip>
            {locale === 'EN' ? `${catelist.sumNiko.nameEN}(Current)` : catelist.sumNiko.nameCN}
          </div>
        </div>,
        key: catelist.sumNiko.cate,
        dataIndex: catelist.sumNiko.cate,
        width: '13%',
        align: 'right',
        render: (record, row, index) => {
          return (
            <div className="custom_txt">{(record && record.toLocaleString())}</div>
          )
        }
      }, {
        title: <div id={'header'} className={`cate_header no_cursor`}>
          <div className="title_name">
            <Tooltip overlayClassName="tooltip" className="text_icon" title={catelist.sumNiko.tips} >
              <QuestionCircleOutlined />
            </Tooltip>
            {locale === 'EN' ? `${catelist.sumNiko.nameEN}(Prior)` : catelist.sumNiko.nameCN}
          </div>
        </div>,
        key: catelist.sumNiko.lastCate,
        dataIndex: catelist.sumNiko.lastCate,
        width: '13%',
        align: 'right',
        render: (record, row, index) => {
          return (
            <div className="custom_txt">
              {(row && row.lastData.length && row.lastData[0][catelist.sumNiko.lastCate] && row.lastData[0][catelist.sumNiko.lastCate].toLocaleString()) ||
                <div style={{ color: 'green' }}>{'-New-'}</div>}
            </div>
          )
        }
      },] : []),
      {
        title: <div className={'cate_header no_cursor'}>{locale === 'EN' ? '% Change' : '变化比例'}</div>,
        key: 'id',
        align: 'right',
        width: '8%',
        render: (record, row, index) => {
          let key = (row[search.cate] - (row.lastData[0] && row.lastData[0][catelist[search.cate].lastCate]) || 0)
          let pointKey
          if (key) {
            pointKey = round(key / (row.lastData[0] && row.lastData[0][catelist[search.cate].lastCate] || 1) * 100)
          }
          return (
            <div className="ranking">
              {
                key !== 0 ? <div className="right_compare">
                  <CaretDownOutlined className={`compareNumber ${key > 0 ? 'upIcon' : null}`} />
                  <span className={`compareNumber span ${key > 0 ? 'upNumber' : null}`}>{Math.abs(pointKey) + "%"}</span>
                </div> : null
              }
            </div>
          )
        }
      },
      {
        title: <div className={'cate_header no_cursor'}>{locale === 'EN' ? 'Current Rank' : '当前排名'}</div>,
        key: 'id',
        width: '8%',
        align: 'right',
        render: (record, row, index) => {
          let key = page > 1 ? (page * perPage) - perPage + index + 1 : index + 1
          return (
            <div className="ranking">{key}</div>
          )
        }
      },
    ]
    return (
      <Table
        loading={pageLoading}
        dataSource={(toplist || {}).data || []}
        columns={columns}
        pagination={pagination}
        rowKey={record => record.gameID}
        bordered={true}
        onChange={this.handleTableChange}
      />
    )
  }
  handleReset = () => {
    this.setState({
      catalogSelected: [
        { value: [] },
        { value: [] },
        { value: [] },
        { value: [] },
        { value: [] },
        { value: '0' },
        { value: 'sumAnchor' },
      ],
      search: {
        cate: 'sumAnchor',
      },
      showMore: false,
    })
  }
  handleTableChange = (pagination, filters, sorter) => {
    const { search } = this.state
    this.props.dispatch({
      type: 'gameList/apiGetTop',
      payload: { page: pagination.current, perPage: pagination.pageSize, ...search }
    })
  }
  handleExport = () => {
    const { page, userInfo } = this.props
    if (userInfo && userInfo.status !== 'test' && userInfo.status !== 'off') {
      const { search } = this.state
      search.page = page
      for (let index in search) {
        if (!search[index]) {
          delete search[index]
        }
      }
      window.open(`${Config.SERVER_HOME}downloadTop?WebAuth=${getToken()}&${qs.stringify(search)}`)
      this.props.dispatch({
        type: 'user/apiPostDownloadFiles',
        payload: { userID: parseInt(userInfo.id), fileID: 10 }
      })
    } else {
      this.props.dispatch({ type: 'user/setState', payload: { forbidDownload: true } })
    }
  }

  render() {
    const { locale, toplist } = this.props
    const { catalog, catalogSelected, showMore, catelist, search, dateVO } = this.state
    let cdata = toplist && toplist.amountDatas && toplist.amountDatas.sumCate
    let ldata = toplist && toplist.amountDatas && toplist.amountDatas.lastSumCate
    let pointChange = round((cdata - ldata) / (ldata || 1) * 100, 2)
    return (
      <BasicLayout selectedKeys={['/compare']}>
        <div className="top_list">
          <div className="top_search">
            <Row>
              {catalog.map((vo, index) => {
                return (
                  <Col key={index} span={24}>
                    <div className="item_line">
                      <div className="title">{locale === 'EN' ? vo.nameEN : vo.nameCN}</div>
                      <div className="button_content">
                        {vo.list.map((item, key) => {
                          if (!showMore && key < 7  ) {
                            return (
                              <div key={key} style={{ cursor: 'pointer' }}
                                className={`button ${(index === 5 || index === catalogSelected.length - 1) ? (catalogSelected[index].value === item.value ? 'button_activity' : null) : (catalogSelected[index].value.indexOf(item.value) >= 0 || (!catalogSelected[index].value[0] && !item.value) ? 'button_activity' : null)}`}
                                onClick={() => { this.handleSelectCatalog(item.value, index) }}
                              >{locale === 'EN' ? item.nameEN : (item.nameCN ? item.nameCN : item.nameEN)}</div>
                            )
                          } else {
                            return (
                              (showMore  ) ? <div key={key} style={{ cursor: 'pointer' }}
                                className={`button ${(index === 5 || index === catalogSelected.length - 1) ? (catalogSelected[index].value === item.value ? 'button_activity' : null) : (catalogSelected[index].value.indexOf(item.value) >= 0 || (!catalogSelected[index].value[0] && !item.value) ? 'button_activity' : null)}`}
                                onClick={() => { this.handleSelectCatalog(item.value, index) }}
                              >{locale === 'EN' ? item.nameEN : (item.nameCN ? item.nameCN : item.nameEN)}</div> : null
                            )
                          }
                        })}
                        {vo.list.length >19? <div className={'button more'}>
                          <span onClick={() => { this.setState({ showMore: !this.state.showMore }) }}>
                            {!showMore ? (locale === 'EN' ? 'Open' : '展开') : (locale === 'EN' ? 'Take back' : '收起')}
                          </span>
                        </div> : null}
                        {vo.nameEN === 'Time Period' ? this.renderDate() : null}
                      </div>
                    </div>
                  </Col>
                )
              })}
            </Row>
          </div>
          <div className="export">
            <div style={{ marginRight: 15, cursor: 'pointer' }} className="reset_button" onClick={() => { this.handleReset() }}>{locale === 'EN' ? 'Reset' : '重置'}</div>
            <div style={{ cursor: 'pointer' }} className="export_button" onClick={() => { this.handleExport() }}><ExportOutlined className="icon" />{locale === 'EN' ? 'Export Data' : '导出'}</div>
          </div>
          <div className={'cate_header compare'}>
            <div >{dateVO.current} {catelist[search.cate].nameEN}:<div style={{ fontSize: 20, fontWeight: 'bolder', display: 'flex' }}>
              {toplist && toplist.amountDatas && toplist.amountDatas.sumCate && toplist.amountDatas.sumCate.toLocaleString()}
            </div></div>
            {
              pointChange !== 0 ? <div className="right_compare" style={{ position: 'relative', margin: '-10vw' }}>
                <CaretDownOutlined className={`compareNumber ${pointChange > 0 ? 'upIcon' : null}`} />
                <span className={`compareNumber span ${pointChange > 0 ? 'upNumber' : null}`}>{Math.abs(pointChange) + "%"}</span>
              </div> : null
            }
            <div style={{ backgroundColor: 'white', width: '3px' }}> </div>
            <div >{dateVO.last} {catelist[search.cate].nameEN}:<div style={{ fontSize: 20, fontWeight: 'bolder' }}>
              {toplist && toplist.amountDatas && toplist.amountDatas.lastSumCate && toplist.amountDatas.lastSumCate.toLocaleString()}
            </div></div>
          </div>
          {this.renderTable()}
        </div>
      </BasicLayout>
    )
  }
}

export default connect(({ gameList, user }) => ({
  toplist: gameList.toplist,
  page: gameList.page,
  perPage: gameList.perPage,
  pageLoading: gameList.pageLoading,
  userInfo: user.userInfo,
  locale: user.locale
}))(GameList)