const DEBUG = false; //等于false时为线上地址，反之测试本地测试地址
const Config = {
  // SERVER_HOME: DEBUG ? 'http://127.0.0.1:10514/web/v1/' : 'http://s.stgame.cn/xf1/web/v1/',
  // UPLOAD_SERVER_HOME: DEBUG ? 'http://127.0.0.1:10514/web/v1/file' : 'http://s.stgame.cn/xf1/web/v1/file',
  // 本地服
  // SERVER_HOME: 'http://127.0.0.1:10514/web/v1/',
  // UPLOAD_SERVER_HOME: 'http://127.0.0.1:10514/web/v1/file',
  // 正式服
  SERVER_HOME: 'https://niko.hugegreen.top/xf1/web/v1/',            //正式服
  UPLOAD_SERVER_HOME: 'https://niko.hugegreen.top/xf1/web/v1/file',   //正式服
};
module.exports = Config;
