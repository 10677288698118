import React, { Component } from 'react'
import { connect } from 'dva'
import BasicLayout from '../../components/BasicLayout'
import './styles.less'
import { Table } from 'antd'
import { routerRedux } from 'dva/router'
import Config from '../../config/Config'
import { getToken } from '../../utils/authority'

class AllCompany extends Component {
  constructor(props) {
    super(props)
    this.state = {

    }
  }

  componentDidMount() {
    this.props.dispatch({
      type: 'company/apiGetCompanyList'
    })
  }

  renderTable = () => {
    const { list, locale } = this.props
    const columns = [
      {
        title: locale==='EN' ? 'Publishers' : '公司',
        key: 'operator',
        width: '30%',
        render: (record, row, index) => {
          return (
            <div style={{display: 'flex', flexDirection: 'row',justifyContent: 'flex-start',alignItems: 'center'}}>
              {record.map((vo)=>{
                return (
                  <div
                    key={vo.id}
                    style={{width: '25%', display: 'flex',flexDirection:'row',alignItems:'center'}}
                    onClick={() => {
                      this.props.dispatch(routerRedux.push(`/company/companyDetails/${vo.id}`))
                    }}
                  >
                    <div style={{width: '150px', height: '70px'}}>
                      {vo.link ? <img src={vo.link} alt={'logo'} style={{width: '100%', height: '100%', objectFit: 'contain'}}/> : null}
                    </div>
                    <div style={{marginLeft: '5px', fontSize: '16px', color: '#333333'}}>{vo.operator}</div>
                  </div>
                )
              })}
            </div>
          )
        }
      }
    ]
    return (
      <Table
        dataSource={list}
        columns={columns}
        pagination={false}
        bordered={true}
        rowKey={(record)=>{return record[0].id}}
      />
    )
  }

  handleExport = () => {
    const { userInfo } = this.props
    if(userInfo && userInfo.status!=='test' && userInfo.status!=='off'){
      window.open(`${Config.SERVER_HOME}operatorfile?WebAuth=${getToken()}`)
      this.props.dispatch({
        type: 'user/apiPostDownloadFiles',
        payload: { userID: parseInt(userInfo.id), fileID: 4 }
      })
    }else{
      this.props.dispatch({ type: 'user/setState', payload: { forbidDownload: true } })
    }
  }

  render() {
    const { locale } = this.props
    return (
      <BasicLayout selectedKeys={['/company']} menu1Name={'Publishers'}>
        <div className="allCompany">
          <div className="top_export">
            <div className="title">{locale==='EN' ? 'Publishers' : '所有公司'}</div>
          </div>
          {this.renderTable()}
        </div>
      </BasicLayout>
    )
  }

}

export default connect(({ company, user })=>({
  list: company.list,
  userInfo: user.userInfo,
  locale: user.locale
}))(AllCompany)