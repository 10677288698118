import { getStreamerList, postFollowed, deleteFollowed, getStreamerDetails, getStreamergift, getStreamerChart } from '../services/streamers'
import { message } from 'antd'
import _ from 'lodash'

export default {
  namespace: 'streamers',
  state: {
    loading: false,
    streamerList: null,
    page: 1,
    perPage: 10,
    flag: false,
    topInfo: null,
    topInfo2024: null,
    topInfo2023: null,
    streamerGift: null,
    spin: false,
    streamerChart: null
  },
  effects: {
    *apiGetClubList({payload}, {call, put}){
      yield put({ type: 'setState', payload: {loading: true, flag: false} })
      const response = yield call(getStreamerList, payload)
      yield put({ type: 'setState', payload: {loading: false, streamerList: response, page: response.page, perPage: response.perPage} })
    },
    *apiPostFollowed({payload}, {call, put}){
      yield call(postFollowed, payload)
      message.success('Subscribed!')
      yield put({ type: 'setState', payload: {flag: true} })
    },
    *apiDeleteFollowed({payload}, {call, put}){
      yield call(deleteFollowed, payload)
      message.success('Unfollow successfully!')
      yield put({ type: 'setState', payload: {flag: true} })
    },
    *apiGetStreamerDetails({payload}, {call, put}){
      yield put({ type: 'setState', payload: {spin: true}})
      const info = yield call(getStreamerDetails, payload)
      if(info && info.userInfo && info.userInfo.gameList){
        info.userInfo.formatGameList = _.uniqBy(info.userInfo.gameList, 'game')
      }
      yield put({ type: 'setState', payload: {spin: false, topInfo: info, }})
    },
    *apiGetStreamerDetails2024({payload}, {call, put}){
      yield put({ type: 'setState', payload: {spin: true}})
      const info = yield call(getStreamerDetails, payload)
      if(info && info.userInfo && info.userInfo.gameList){
        info.userInfo.formatGameList = _.uniqBy(info.userInfo.gameList, 'game')
      }
      yield put({ type: 'setState', payload: {spin: false, topInfo2024: info, }})
    },
    *apiGetStreamerDetails2023({payload}, {call, put}){
      yield put({ type: 'setState', payload: {spin: true}})
      const info = yield call(getStreamerDetails, payload)
      if(info && info.userInfo && info.userInfo.gameList){
        info.userInfo.formatGameList = _.uniqBy(info.userInfo.gameList, 'game')
      }
      yield put({ type: 'setState', payload: {spin: false, topInfo2023: info, }})
    },
    *apiGetStreamerChart({payload}, {call, put}){
      let response = yield call(getStreamerChart, payload)
      yield put({ type: 'setState', payload: {streamerChart: response.data}})
    }
  },
  reducers: {
    setState(state, {payload}){
      return {
        ...state,
        ...payload
      }
    }
  }
}