import React from 'react'
import { Router, Route, Switch, Redirect } from 'dva/router'

function RouterConfig({ history }) {
  return (
    <Router history={history}>
      <Switch>
        <Route path="/" exact render={()=>(<Redirect to={{pathname: '/home'}}/>)} />
        <Route path="/login" exact component={require('./routes/login').default} />
        <Route path="/home" exact component={require('./routes/index').default} />
        <Route path="/home/searchResult" exact component={require('./routes/searchResult').default} />
        <Route path="/gameList" exact component={require('./routes/gameList').default} />
        <Route path="/gameList/gameDetails/:id" exact component={require('./routes/gameDetails').default} />
        <Route path="/company" exact component={require('./routes/allCompany').default} />
        <Route path="/company/companyDetails/:id" exact component={require('./routes/companyDetails').default} />
        <Route path="/developer" exact component={require('./routes/developer').default} />
        <Route path="/developer/details/:id" exact component={require('./routes/developer/developerDetails').default} />
        <Route path="/streamers" exact component={require('./routes/streamers/streamers').default} />
        <Route path="/streamerClubs" exact component={require('./routes/streamers/streamerClubs').default} />
        <Route path="/streamers/streamerDetails/:id" exact component={require('./routes/streamers/streamerDetails').default} />
        <Route path="/streamers/clubDetails/:name" exact component={require('./routes/streamers/clubDetails').default} />
        <Route path="/contactUs" exact component={require('./routes/contactUs').default}/>
        <Route path="/niko" exact component={require('./routes/aboutNiko').default}/>
        <Route path="/personal" exact component={require('./routes/personalInfo').default}/>
        <Route path="/myFocus" exact component={require('./routes/myFocus').default}/>
        <Route path="/faq" exact component={require('./routes/faq/faq').default}/>
        <Route path="/compare" exact component={require('./routes/rank').default}/>
        <Route path="/allmetrics" exact component={require('./routes/overview').default}/>
      </Switch>
    </Router>
  )
}
export default RouterConfig