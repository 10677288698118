import React, { Component } from 'react'
import './styles.less'
import { Input, Form, Button,Tooltip } from 'antd'
import { UserOutlined, UnlockOutlined } from '@ant-design/icons'
import { connect } from 'dva'
import { QuestionCircleOutlined } from '@ant-design/icons'

const FormItem = Form.Item

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            locale: "EN",
            username: null,
            password: null,
        }
    }

    onLoginFinish = ( values ) => {
        this.props.dispatch({
            type: 'login/apiPostUserLogin',
            payload: {...values}
        })
    }

    render() {
        const { locale } = this.state
        return (
            <div className="login_contain">
                <div className="loginCard">
                    <div className="form_title">
                        <div className="logo"><img src={require('../../assets/common/logo.png')} alt={'logo'}/></div>
                        <div className="title">Streaming Tracker</div>
                    </div>
                    <Form onFinish={this.onLoginFinish}>
                        <FormItem name={'username'} rules={[{required: true, message: locale==='EN' ? 'Enter your account' : '输入您的账号'}]}>
                            <div className="form_item">
                                <Input placeholder={locale==='EN' ? 'Account' : '输入您的账号'} className="input"/>
                                <UserOutlined className="icon"/>
                            </div>
                        </FormItem>
                        <FormItem
                          name={'password'}
                          rules={[{required: true, message: locale==='EN' ? 'Enter your password' : '输入您的密码'}]}
                        >
                            <div className="form_item">
                                <Input.Password placeholder={locale==='EN' ? 'Password' : '输入您的密码'} className="input"/>
                                <UnlockOutlined className="icon"/>
                            </div>
                        </FormItem>
                        <FormItem style={{textAlign: 'center'}}>
                            <Button type={'primary'} htmlType={'submit'} className="submit_button">{locale==='EN' ? 'Sign In' : '登录'}</Button>
                        </FormItem>
                        <div></div>
                            <Tooltip overlayClassName="tooltip" className="text_icon" title="If you forgot your password Please send an email to contact XiaoFeng <xiaofeng@nikopartners.com>.">
                                <QuestionCircleOutlined /> 
                                <span style={{color:'#3273B1'}}>  Forgot password</span>
                            </Tooltip>
                    </Form>
                </div>
            </div>
        )
    }

}
export default connect(({ login })=>({
    loginStatus: login.loginStatus,
}))(Login)