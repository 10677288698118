import React, { Component } from 'react'
import BasicLayout from '../../components/BasicLayout'
import './styles.less'
import { connect } from 'dva'
import { Table, Space, Tooltip, Row, Col, DatePicker } from 'antd'
import { ExportOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import NumericConversion from '../../utils/utils'
import { routerRedux } from 'dva/router'
import moment from 'moment'

import qs from 'query-string';
import Config from '../../config/Config'
import { getToken } from '../../utils/authority'

const { RangePicker } = DatePicker

function disabledDate(current) {
  let time = moment().add(1, 'days').format('YYYY-MM-DD');
  return current && !moment(current).isBefore(time);
}

class Streamers extends Component {
  constructor(props) {
    super(props)
    this.state = {
      catalog: [
        {
          nameEN: 'Metrics',
          nameCN: '维度',
          list: [
            { nameEN: 'Subscribers', nameCN: '游戏粉丝数', value: 'avgFans' },
            { nameEN: 'Tips', nameCN: '游戏打赏收入', value: 'sumGift' },
            { nameEN: 'Heat Index', nameCN: '游戏热力值', value: 'sumHot' },
            { nameEN: 'Niko Index', nameCN: 'NIKO指数', value: 'sumNiko' },
            { nameEN: 'Hours Streamed', nameCN: '主播时长', value: 'sumLenth' },
          ]
        },
        {
          nameEN: 'Streaming Platform',
          nameCN: '流媒体网站',
          list: [
            { nameEN: 'All', value: null },
            { nameEN: 'DouYu', value: '103' },
            { nameEN: 'HuYa', value: '102' },
            { nameEN: 'Bilibili', value: '101' },
          ]
        },
        {
          nameEN: 'Game Segment',
          nameCN: '平台',
          list: [
            { nameEN: 'All', value: null },
            { nameEN: 'PC', value: 'PC' },
            { nameEN: 'Mobile', value: 'Mobile' },
            { nameEN: 'Console', value: 'Console' },
            // {nameEN: 'Non-game', value: 'Non-game'}
          ]
        },
        {
          nameEN: 'Genre',
          nameCN: '类型',
          list: [
            { nameEN: 'All', value: null },
            { nameEN: 'MOBA', value: 'MOBA' },
            { nameEN: 'Battle Royale', value: 'Battle Royale' },
            { nameEN: 'RPG', value: 'RPG' },
            // {nameEN: 'Non-game', value: 'Non-game'},
            { nameEN: 'FPS/TPS', value: 'FPS/TPS' },
            { nameEN: 'Auto Chess', value: 'Auto Chess' },
            // {nameEN: 'Series games', value: 'Series games'},
            { nameEN: 'Racing', value: 'Racing' },
            { nameEN: 'Sandbox', value: 'Sandbox' },
            { nameEN: 'Trading Card', value: 'Trading Card' },
            { nameEN: 'Strategy', value: 'Strategy' },
            { nameEN: 'RTS', value: 'RTS' },
            { nameEN: 'Action', value: 'Action' },
            { nameEN: 'Sporting', value: 'Sporting' },
            { nameEN: 'Horror', value: 'Horror game' },
            { nameEN: 'Puzzle', value: 'Puzzle' },
            { nameEN: 'Simulation', value: 'Simulation' },
            { nameEN: 'Adventure', value: 'Adventure' },
            { nameEN: 'Poker & Mahjong', value: 'Poker & Mahjong' },
            { nameEN: 'Running', value: 'Running' },
            { nameEN: 'Casual', value: 'Casual' },
            { nameEN: 'Idle', value: 'Idle' },
            { nameEN: 'Dancing', value: 'Dancing' },
            { nameEN: 'Roguelike', value: 'Roguelike' },
          ]
        },
        {
          nameEN: 'Developers',
          nameCN: '发展商',
          list: [
            {nameEN: 'All', value: null},
            {nameEN: 'Tencent', value: 615},
            {nameEN: 'NetEase', value: 617},
            {nameEN: 'Steam', value: 616},
            {nameEN: 'Mihoyo Game', value: 627},
            {nameEN: 'EA', value: 622},
            {nameEN: 'Ubisoft', value: 690},
            {nameEN: 'Perfect World', value: 618},
            {nameEN: 'Nexon', value: 766},
            {nameEN: 'Blizzard', value: 621},
            {nameEN: 'SCE', value: 653},
            {nameEN: 'Nintendo', value: 647},
            {nameEN: 'Shengqu', value: 623},
            {nameEN: '37 Game', value: 686},
            {nameEN: '4399 Game', value: 752},
            {nameEN: 'Leiting Game', value: 691},
            {nameEN: 'Activison', value: 1065},
            {nameEN: 'Take-Two', value: 790},
            {nameEN: 'Bilibili', value: 631},
            {nameEN: 'Other', value: 'other'},
          ]
        },
        {
          nameEN: 'Time Period',
          nameCN: '时间段',
          list: [
            { nameEN: 'Day', nameCN: '昨天', value: '0' },
            { nameEN: 'Last week', nameCN: '近一周', value: '1' },
            { nameEN: 'Last month', nameCN: '近一月', value: '2' },
            { nameEN: 'Customize', nameCN: '自定义', value: '3' }
          ]
        }
      ],
      catalogSelected: [
        { value: 'avgFans' },
        { value: [] },
        { value: [] },
        { value: [] },
        { value: [] },
        { value: '0' }
      ],
      date: [],
      search: {
        cate: 'avgFans',
      },
      gameID: localStorage.getItem('xf1_web_gameID') || undefined,
      showMore: false,
    }
  }

  componentDidMount() {
    const { search, gameID } = this.state
    this.props.dispatch({
      type: 'streamers/apiGetClubList',
      payload: { page: 1, perPage: 20, ...search, gameID }
    })
  }
  UNSAFE_componentWillUpdate(nextProps, nextState, nextContext) {
    const { perPage, flag } = this.props
    const { catalogSelected, date, gameID } = this.state
    if ((catalogSelected !== nextState.catalogSelected && nextState.catalogSelected) || (date !== nextState.date) || (gameID !== nextState.gameID) || (flag !== nextProps.flag && nextProps.flag)) {
      let payload = {}
      if (nextState.gameID) {
        payload.gameID = nextState.gameID
      }
      if (nextState.date[0]) {
        payload.startDate = nextState.date[0]
      }
      if (nextState.date[1]) {
        payload.endDate = nextState.date[1]
      }
      if (nextState.catalogSelected[1].value.length !== 0) {
        payload.platformID = nextState.catalogSelected[1].value.map((vo) => { return vo }).join(',')
      }
      if (nextState.catalogSelected[2].value.length !== 0) {
        payload.Platform = nextState.catalogSelected[2].value.map((vo) => { return vo }).join(',')
      }
      if (nextState.catalogSelected[3].value.length !== 0) {
        payload.gameGenre = nextState.catalogSelected[3].value.map((vo) => { return vo }).join(',')
      }
      if (nextState.catalogSelected[4].value.length !== 0) {
        payload.DeveloperID = nextState.catalogSelected[4].value.map((vo) => { return vo }).join(',')
      }
      if (nextState.catalogSelected[0].value) {
        payload.cate = nextState.catalogSelected[0].value
      }
      this.setState({ search: payload })
      this.props.dispatch({
        type: 'streamers/apiGetClubList',
        payload: { page: 1, perPage: perPage, ...payload }
      })
    }
  }

  componentWillUnmount() {
    localStorage.setItem('xf1_web_gameID', '')
  }

  handleReset = () => {
    localStorage.setItem('xf1_web_gameID', '')
    this.setState({
      catalogSelected: [
        { value: 'avgFans' },
        { value: [] },
        { value: [] },
        { value: [] },
        { value: [] },
        { value: '0' }
      ],
      date: [],
      search: {
        cate: 'avgFans',
      },
      gameID: null,
    })
  }
  //导出
  handleExport = () => {
    const { userInfo } = this.props
    const { id, search } = this.state
    if (userInfo && userInfo.status !== 'test' && userInfo.status !== 'off') {
      window.open(`${Config.SERVER_HOME}downloadStreamer?WebAuth=${getToken()}&${qs.stringify(search)}`)
      this.props.dispatch({
        type: 'user/apiPostDownloadFiles',
        payload: { userID: parseInt(userInfo.id), fileID: 7 }
      })
    } else {
      this.props.dispatch({ type: 'user/setState', payload: { forbidDownload: true } })
    }
  }

  handleFollowed = (record) => {
    if (record.isLike) {
      this.props.dispatch({
        type: 'streamers/apiDeleteFollowed',
        payload: { genID: record.genID }
      })
    } else {
      this.props.dispatch({
        type: 'streamers/apiPostFollowed',
        payload: { genID: record.genID }
      })
    }
  }
  handleSelectCatalog = (item, index) => {
    const { catalogSelected, date } = this.state
    if (index === 0 || index === catalogSelected.length - 1) {
      if (catalogSelected[index].value === item) {
        catalogSelected[index].value = null
        this.setState({ date: [] })
        return
      }
      catalogSelected[index].value = item
      if (index === catalogSelected.length - 1) {
        if (item && (item === '0' || item === '3')) {
          this.setState({ date: [] })
          return
        } else if (item && item === '1') {
          date[0] = moment().subtract(7, 'days').format('YYYY-MM-DD')
          date[1] = moment().subtract(1, 'days').format('YYYY-MM-DD')
        } else if (item && item === '2') {
          date[0] = moment().subtract(30, 'days').format('YYYY-MM-DD')
          date[1] = moment().subtract(1, 'days').format('YYYY-MM-DD')
        }
        this.setState({ date: date.concat() })
      }
    } else {
      if (index === 4 && item === 'other') {
        this.props.dispatch(routerRedux.push('/developer'))
        return
      }
      let key = catalogSelected[index].value.indexOf(item)
      if (key < 0) {
        if (!item) {
          catalogSelected[index].value = []
        } else {
          catalogSelected[index].value.push(item)
        }
      } else {
        catalogSelected[index].value.splice(key, 1)
      }
    }
    this.setState({ catalogSelected: catalogSelected.concat() })
  }
  handleChangeDate = (time, dateString) => {
    const { date } = this.state
    if (time) {
      date[0] = moment(time[0]).format('YYYY-MM-DD')
      date[1] = moment(time[1]).format('YYYY-MM-DD')
      this.setState({ date: date.concat() })
    }
  }
  renderTable = () => {
    const { search } = this.state
    const { loading, streamerList, page, perPage, locale } = this.props
    const pagination = {
      current: parseInt(page) || 0,
      total: (streamerList || {}).total || 0,
      defaultCurrent: 1,
      defaultPageSize: parseInt(perPage) || 0,
      pageSize: perPage,
      showQuickJumper: true,
      position: 'bottom',
    }
    const columns = [
      {
        title: <div className="unCategoryHeader">Rank</div>,
        key: 'id',
        width: '3%',
        render: (record, row, index) => {
          let key = page > 1 ? (page * perPage) - perPage + index + 1 : index + 1
          return (
            <div className="ranking">{key}</div>
          )
        }
      },
      {
        title: <div className="unCategoryHeader">Name</div>,
        dataIndex: 'name',
        key: 'name',
        width: '15%',
        render: (text, record, index) => {
          return (
            <Space style={{ cursor: 'pointer' }} onClick={() => { this.props.dispatch(routerRedux.push(`/streamers/streamerDetails/${record.genID}`)) }}>
              <div className="streamerLogo">
                <img src={record.avatar} />
              </div>
              <div>
                <div>{record.enName}</div>
                <div>{text}</div>
                <div key={index} style={{ fontSize: 12, color: '#F4791F' }}>{(record.clubInfo && record.clubInfo.name) || record.official}</div>
              </div>
            </Space>
          )
        }
      },
      {
        title: <div className="unCategoryHeader">Game</div>,
        dataIndex: ['gameInfo', 'gameEn'],
        key: ['gameInfo', 'gameEn'],
        width: '15%',
        render: (text, record, index) => {
          return (
            <div className="game Info" key={index}>
              <Space style={{ cursor: 'pointer' }} onClick={() => { this.setState({ gameID: record.gameInfo.id }) }} >
                {record.gameInfo && (
                  <div className='gamelogo'>
                    <img src={record.gameInfo.link} style={{ width: 40 }} />
                  </div>
                )}
                {text && (<div style={{ display: 'inline-block', width: 120, textAlign: 'center' }}>{text}</div>)}
              </Space>
              <div style={{ textAlign: 'center', fontSize: 12, color: '#F4791F' }}>{record.game}</div>
            </div>
          )
        }
      },
      {
        title: <div onClick={() => { this.handleSelectCatalog('avgFans', 0) }} className={`cate_header ${search.cate === 'avgFans' ? 'cate_selected' : null}`}>
          <div className="title_name">
            <Tooltip overlayClassName="tooltip" className="text_icon" title="For Games, this measures the daily average number of subscribers that are subscribed to streamers of a game or category during the selected time period. For streamers this measures the total number of subscribers for the streamers channel.">
              <QuestionCircleOutlined />
            </Tooltip>
            {locale === 'EN' ? 'Subscribers' : '游戏粉丝数'}
          </div>
          {streamerList && streamerList.amountDatas ? <div className={`total ${search.cate === 'avgFans' ? 'total_number' : null}`}>{NumericConversion(streamerList.amountDatas[0].avgFans)}</div> : null}
        </div>,
        width: '8%',
        key: 'avgFans',
        dataIndex: 'avgFans',
        align: 'right',
        render: (record, row, index) => {
          return (
            <div className="custom_txt" style={search.cate === 'avgFans' ? { color: '#F4791F' } : null}>{NumericConversion(record)}</div>
          )
        }
      },
      {
        title: <div onClick={() => { this.handleSelectCatalog('sumGift', 0) }} className={`cate_header ${search.cate === 'sumGift' ? 'cate_selected' : null}`}>
          <div className="title_name">
            <Tooltip overlayClassName="tooltip" className="text_icon" title="This measures the cumulative value of tips and virtual gifts that streamers receive within the game, category or as an individual streamer. This is measured in RMB and converted to USD.">
              <QuestionCircleOutlined />
            </Tooltip>
            {locale === 'EN' ? 'Tips' : '游戏打赏收入'}
          </div>
          {streamerList && streamerList.amountDatas ? <div className={`total ${search.cate === 'sumGift' ? 'total_number' : null}`}>{locale === 'EN' ? NumericConversion(streamerList.amountDatas[0].USsumGift) : NumericConversion(streamerList.amountDatas[0].sumGift)}</div> : null}
        </div>,
        key: 'sumGift',
        dataIndex: locale === 'EN' ? 'USsumGift' : 'sumGift',
        width: '8%',
        align: 'right',
        render: (record, text) => {
          return (
            <div className="custom_txt" style={search.cate === 'sumGift' ? { color: '#F4791F' } : null}>{NumericConversion(record)}</div>
          )
        }
      },
      {
        title: <div onClick={() => { this.handleSelectCatalog('sumHot', 0) }} className={`cate_header ${search.cate === 'sumHot' ? 'cate_selected' : null}`}>
          <div className="title_name">
            <Tooltip overlayClassName="tooltip" className="text_icon" title="An index based on the daily average value of three streaming platforms’ own algorithms, providing a rating of the games number of viewers, hours streamed, and number of virtual gifts or tips donated.">
              <QuestionCircleOutlined />
            </Tooltip>
            {locale === 'EN' ? 'Heat Index' : '游戏热力值'}
          </div>
          {streamerList && streamerList.amountDatas ? <div className={`total ${search.cate === 'sumHot' ? 'total_number' : null}`}>{NumericConversion(streamerList.amountDatas[0].avgHot)}</div> : null}
        </div>,
        key: 'avgHot',
        width: '8%',
        dataIndex: 'avgHot',
        align: 'right',
        render: (record, text) => {
          return (
            <div className="custom_txt" style={search.cate === 'sumHot' ? { color: '#F4791F' } : null}>{NumericConversion(record)}</div>
          )
        }
      },
      {
        title: <div onClick={() => { this.handleSelectCatalog('sumNiko', 0) }} className={`cate_header ${search.cate === 'sumNiko' ? 'cate_selected' : null}`}>
          <div className="title_name">
            <Tooltip overlayClassName="tooltip" className="text_icon" title="A weighted index estimated by Niko based on an algorithm approximating the number of active viewers of each streamer, game or category. This is a cumulative figure for the period selected.">
              <QuestionCircleOutlined />
            </Tooltip>
            {locale === 'EN' ? 'Niko Index' : 'Niko指数'}
          </div>
          {streamerList && streamerList.amountDatas ? <div className={`total ${search.cate === 'sumNiko' ? 'total_number' : null}`}>{NumericConversion(streamerList.amountDatas[0].sumNiko)}</div> : null}
        </div>,
        key: 'sumNiko',
        dataIndex: 'sumNiko',
        width: '8%',
        align: 'right',
        render: (record, text) => {
          return (
            <div className="custom_txt" style={search.cate === 'sumNiko' ? { color: '#F4791F' } : null}>{NumericConversion(record)}</div>
          )
        }
      },
      {
        title: <div onClick={() => { this.handleSelectCatalog('sumLenth', 0) }} className={`cate_header ${search.cate === 'sumLenth' ? 'cate_selected' : null}`}>
          <div className="title_name">
            <Tooltip overlayClassName="tooltip" className="text_icon" title="This measures the cumulative number of hours streamed for the game, category or individual streamer across the time period selected.">
              <QuestionCircleOutlined />
            </Tooltip>
            {locale === 'EN' ? 'Hours' : '主播时长'}
          </div>
          {streamerList && streamerList.amountDatas ? <div className={`total ${search.cate === 'sumLenth' ? 'total_number' : null}`}>{NumericConversion(streamerList.amountDatas[0].sumLenth)}</div> : null}
        </div>,
        key: 'sumLenth',
        dataIndex: 'sumLenth',
        width: '6%',
        align: 'right',
        render: (record, row, index) => {
          return (
            <div className="custom_txt" style={search.cate === 'sumLenth' ? { color: '#F4791F' } : null}>{NumericConversion(record)}</div>
          )
        }
      },
      {
        title: <div className={'cate_header'}>Follow</div>,
        key: 'action',
        width: '3%',
        align: 'center',
        fixed: 'right',
        render: (text, record, index) => {
          return (
            <div
              className="followedIcon"
              onClick={() => { this.handleFollowed(record) }}
            >
              <img src={record.isLike ? require('../../assets/common/followed.png') : require('../../assets/common/unFollowed.png')} />
            </div>
          )
        }
      }
    ]
    return (
      <Table
        bordered={true}
        loading={loading}
        dataSource={(streamerList || {}).data || []}
        columns={columns}
        pagination={pagination}
        rowKey={record => record.id}
        // scroll={{x: "max-content"}}
        onChange={(pagination, filters, sorter) => {
          this.props.dispatch({
            type: 'streamers/apiGetClubList',
            payload: { page: pagination.current, perPage: pagination.pageSize, ...search }
          })
        }}
      />
    )
  }
  render() {
    const { locale } = this.props
    const { catalog, catalogSelected, showMore } = this.state
    return (
      <BasicLayout selectedKeys={['/streamers']} menu2Name={'Streamers'}>
        <div className="streamersContain">
          <div className="top_search">
            <Row>
              {catalog.map((vo, index) => {
                return (
                  <Col key={index} span={24}>
                    <div className="item_line">
                      <div className="title">{locale === 'EN' ? vo.nameEN : vo.nameCN}</div>
                      <div className="button_content">
                        {vo.list.map((item, key) => {
                          if (!showMore && key < 7) {
                            return (
                              <div key={key}
                                className={`button ${index === 0 || index === catalogSelected.length - 1 ? (catalogSelected[index].value === item.value ? 'button_activity' : null) : (catalogSelected[index].value.indexOf(item.value) >= 0 || (!catalogSelected[index].value[0] && !item.value) ? 'button_activity' : null)}`}
                                onClick={() => { this.handleSelectCatalog(item.value, index) }}
                              >{locale === 'EN' ? item.nameEN : (item.nameCN ? item.nameCN : item.nameEN)}</div>
                            )
                          } else {
                            return (
                              showMore ? <div key={key}
                                className={`button ${index === 0 || index === catalogSelected.length - 1 ? (catalogSelected[index].value === item.value ? 'button_activity' : null) : (catalogSelected[index].value.indexOf(item.value) >= 0 || (!catalogSelected[index].value[0] && !item.value) ? 'button_activity' : null)}`}
                                onClick={() => { this.handleSelectCatalog(item.value, index) }}
                              >{locale === 'EN' ? item.nameEN : (item.nameCN ? item.nameCN : item.nameEN)}</div> : null
                            )
                          }
                        })}
                        {vo.list.length>19 ? <div className={'button more'}>
                          <span onClick={() => { this.setState({ showMore: !this.state.showMore }) }}>
                            {!showMore ? (locale === 'EN' ? 'Open' : '展开') : (locale === 'EN' ? 'Take back' : '收起')}
                          </span>
                        </div> : null}
                        {
                          index === catalog.length - 1 && catalogSelected[index].value === '3'
                            ?
                            <RangePicker
                              disabledDate={disabledDate}
                              onChange={this.handleChangeDate}
                              style={{ padding: '2px 14px 2px', margin: '0 13px 13px 0' }}
                            />
                            :
                            null
                        }
                      </div>
                    </div>
                  </Col>
                )
              })}
            </Row>
          </div>
          <div className="export">
            <Space>
              <div className="export_button" onClick={() => { this.handleReset() }}>Reset</div>
              <div className="export_button" onClick={() => { this.handleExport() }}><ExportOutlined className="icon" />{locale === 'EN' ? 'Export Data' : '导出'}</div>
            </Space>
          </div>
          {this.renderTable()}
        </div>
      </BasicLayout>
    )
  }

}
export default connect(({ streamers, user }) => ({
  loading: streamers.loading,
  streamerList: streamers.streamerList,
  page: streamers.page,
  perPage: streamers.perPage,
  flag: streamers.flag,
  userInfo: user.userInfo,
  locale: user.locale
}))(Streamers)
