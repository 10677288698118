import React, { Component } from 'react';
import BasicLayout from '../../components/BasicLayout'
import { connect } from 'dva'
import { Space, Spin, Table, Tooltip, Row, Col, Card, DatePicker } from 'antd'
import './styles.less'
import { routerRedux } from 'dva/router'
import { CaretDownOutlined, CaretUpOutlined, ExportOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import NumericConversion from '../../utils/utils'
import moment from 'moment'
import ReactEcharts from 'echarts-for-react'
import _ from 'lodash'

import qs from 'query-string';
import Config from '../../config/Config'
import { getToken } from '../../utils/authority'

const { RangePicker } = DatePicker;

function disabledDate(current) {
  let time = moment().add(1, 'days').format('YYYY-MM-DD');
  return current && !moment(current).isBefore(time);
}

class ClubDetails extends Component {
  constructor(props) {
    super(props)
    let name = props.match.params.name;
    this.state = {
      timeValue: null,
      gameDate: [],
      name: name,
      catalog: [
        {
          nameEN: 'Dimension',
          nameCN: '维度',
          list: [
            {
              nameEN: 'Streamers',
              nameCN: '主播数量',
              value: 'sumAnchor',
              icon: require('../../assets/icon/icon1.png'),
              icon_selected: require('../../assets/icon/icon1_selected.png'),
            },
            {
              nameEN: 'Streamed Hours',
              nameCN: '主播时长',
              value: 'sumLenth',
              icon: require('../../assets/icon/icon2.png'),
              icon_selected: require('../../assets/icon/icon2_selected.png'),
            },
            {
              nameEN: 'Subscribers',
              nameCN: '游戏粉丝数',
              value: 'avgFans',
              icon: require('../../assets/icon/icon3.png'),
              icon_selected: require('../../assets/icon/icon3_selected.png'),
            },
            {
              nameEN: 'Tips',
              nameCN: '游戏打赏收入',
              value: 'sumGift',
              icon: require('../../assets/icon/icon4.png'),
              icon_selected: require('../../assets/icon/icon4_selected.png'),
            },
            {
              nameEN: 'Heat Index',
              nameCN: '游戏热力值',
              value: 'avgHot',
              icon: require('../../assets/icon/icon5.png'),
              icon_selected: require('../../assets/icon/icon5_selected.png'),
            },
            {
              nameEN: 'Niko Index',
              nameCN: 'NIKO指数',
              value: 'sumNiko',
              icon: require('../../assets/common/logo.png'),
              icon_selected: require('../../assets/common/logo.png'),
            }
          ]
        },
        {
          nameEN: 'Time Period',
          nameCN: '时间段',
          list: [
            { nameEN: 'Last week', nameCN: '近一周', value: '0' },
            { nameEN: 'Last month', nameCN: '近一月', value: '1' },
            { nameEN: 'Customize', nameCN: '自定义', value: '2' }
          ]
        }
      ],
      catalogSelected: [
        { value: 'sumAnchor' },
        { value: '0' }
      ],
      date: [],
      search: {
        cate: 'avgFans',
      },
      gameID: null,
      xAxis: [],  //格式化图表所需数据
      dData: [], //斗鱼
    }
  }

  componentDidMount() {
    document.documentElement.scrollTop = 0
    const { name, search, catalogSelected, date, gameID } = this.state
    this.props.dispatch({
      type: 'streamerClubs/apiGetClubDetails',
      payload: { name }
    })
    this.props.dispatch({
      type: 'streamerClubs/apiGetClubStreamers',
      payload: { page: 1, perPage: 20, ...search, name, gameID }
    })
    this.fetchClubChart({
      date: date,
      catalogSelected: catalogSelected
    })
  }

  UNSAFE_componentWillUpdate(nextProps, nextState, nextContext) {
    const { name, search, catalogSelected, gameID, gameDate } = this.state
    const { flag, clubChart } = this.props
    if ((search !== nextState.search && nextState.search)
      || (flag !== nextProps.flag && nextProps.flag)
      || (gameDate !== nextState.gameDate && nextState.gameDate)
      || (gameID !== nextState.gameID)) {
      this.props.dispatch({
        type: 'streamerClubs/apiGetClubStreamers',
        payload: {
          page: 1,
          perPage: nextProps.clubStreamers && nextProps.clubStreamers.perPage,
          ...nextState.search,
          name,
          startDate: gameDate[0], endDate: gameDate[1],
          gameID: nextState.gameID
        }
      })
      this.props.dispatch({
        type: 'streamers/setState',
        payload: { flag: false }
      })
    }
    if (clubChart !== nextProps.clubChart && nextProps.clubChart) {
      this.formatData(nextProps.clubChart, nextProps.locale)
    }
    if (catalogSelected !== nextState.catalogSelected && nextState.catalogSelected) {
      this.fetchClubChart({
        date: nextState.date,
        catalogSelected: nextState.catalogSelected
      })
    }
  }

  fetchClubChart = (searchParams) => {
    let { name } = this.state
    let payload = {}
    payload.startDate = searchParams.date[0] ? searchParams.date[0] : moment().subtract(7, 'days').format('YYYY-MM-DD')
    payload.endDate = searchParams.date[1] ? searchParams.date[1] : moment().subtract(1, 'days').format('YYYY-MM-DD')
    payload.cate = searchParams.catalogSelected[0].value
    payload.name = name
    this.props.dispatch({
      type: 'streamerClubs/apiGetClubChat',
      payload
    })
  }

  handleChangeSearch = (item, index) => {
    const { search, gameDate } = this.state
    if (gameDate.length > 1) {
      search.startDate = gameDate[0]
      search.endDate = gameDate[1]
    }
    search.cate = item
    this.setState({ search: Object.assign({}, search) })
  }

  handleSelectCatalog = (item, index) => {
    const { catalogSelected, date } = this.state
    catalogSelected[index].value = item
    if (index === catalogSelected.length - 1) {
      if (item && item === '0') {
        date[0] = moment().subtract(7, 'days').format('YYYY-MM-DD')
        date[1] = moment().subtract(1, 'days').format('YYYY-MM-DD')
      } else if (item && item === '1') {
        date[0] = moment().subtract(30, 'days').format('YYYY-MM-DD')
        date[1] = moment().subtract(1, 'days').format('YYYY-MM-DD')
      } else if (item && item === '2') {
        this.setState({ date: [] })
        return
      }
      this.setState({ date: date.concat() })
    }
    this.setState({ catalogSelected: catalogSelected.concat() })
  }

  handleChangeDate = (time, dateString) => {
    const { date } = this.state
    if (time) {
      date[0] = moment(time[0]).format('YYYY-MM-DD')
      date[1] = moment(time[1]).format('YYYY-MM-DD')
      this.setState({ date: date.concat() })
    }
  }

  handleFollowed = (record) => {
    if (record.isLike) {
      this.props.dispatch({
        type: 'streamers/apiDeleteFollowed',
        payload: { genID: record.genID }
      })
    } else {
      this.props.dispatch({
        type: 'streamers/apiPostFollowed',
        payload: { genID: record.genID }
      })
    }
  }
  //导出
  handleExport = () => {
    const { userInfo } = this.props
    const { id, search, name } = this.state
    console.log(userInfo)
    if (userInfo && userInfo.status !== 'test' && userInfo.status !== 'off') {
      search.name = name
      console.log(`${Config.SERVER_HOME}downloadStreamer?WebAuth=${getToken()}&${qs.stringify(search)}`)
      window.open(`${Config.SERVER_HOME}downloadStreamer?WebAuth=${getToken()}&${qs.stringify(search)}`)
      this.props.dispatch({
        type: 'user/apiPostDownloadFiles',
        payload: { userID: parseInt(userInfo.id), fileID: 7 }
      })
    } else {
      this.props.dispatch({ type: 'user/setState', payload: { forbidDownload: true } })
    }
  }

  renderTable = () => {
    const { search, name, gameID, gameDate } = this.state
    const { spin, tableLoading, clubStreamers, locale } = this.props
    const pagination = {
      current: parseInt(clubStreamers && clubStreamers.page) || 0,
      total: (clubStreamers || {}).total || 0,
      defaultCurrent: 1,
      defaultPageSize: parseInt(clubStreamers && clubStreamers.perPage) || 0,
      pageSize: clubStreamers && clubStreamers.perPage,
      showQuickJumper: true,
      position: 'bottom',
    }
    const columns = [
      {
        title: <div className="unCategoryHeader">Rank</div>,
        key: 'id',
        width: '5%',
        render: (record, row, index) => {
          let key = clubStreamers && clubStreamers.page > 1 ? (clubStreamers.page * clubStreamers.perPage) - clubStreamers.perPage + index + 1 : index + 1
          return (
            <div className="ranking">{key}</div>
          )
        }
      },
      {
        title: <div className="unCategoryHeader">Name</div>,
        dataIndex: 'name',
        key: 'name',
        width: '15%',
        render: (text, record, index) => {
          return (
            <Space style={{ cursor: 'pointer' }} onClick={() => { this.props.dispatch(routerRedux.push(`/streamers/streamerDetails/${record.genID}`)) }}>
              <div className="streamerLogo">
                <img src={record.avatar} />
              </div>
              <div>
                <div>{record.enName}</div>
                <div>{text}</div>
                <div key={index} style={{ fontSize: 12, color: '#F4791F' }}>{record.clubInfo && record.clubInfo.name}</div>
              </div>
            </Space>
          )
        }
      },
      {
        title: <div className="unCategoryHeader">Game</div>,
        dataIndex: ['gameInfo', 'gameEn'],
        key: ['gameInfo', 'gameEn'],
        width: '15%',
        render: (text, record, index) => {
          return (
            <div className="game Info" key={index}>
              <Space style={{ cursor: 'pointer' }} onClick={() => { this.setState({ gameID: record.gameInfo.id }) }} >
                {record.gameInfo && (
                  <div className='gamelogo'>
                    <img src={record.gameInfo.link} style={{ width: 40 }} />
                  </div>
                )}
                {text && (<div style={{ display: 'inline-block', width: 120, textAlign: 'center' }}>{text}</div>)}
              </Space>
              <div style={{ textAlign: 'center', fontSize: 12, color: '#F4791F' }}>{record.game}</div>
            </div>
          )
        }
      },
      {
        title: <div onClick={() => { this.handleChangeSearch('avgFans', 0) }} className={`cate_header ${search.cate === 'avgFans' ? 'cate_selected' : null}`}>
          <div className="title_name">
            <Tooltip overlayClassName="tooltip" className="text_icon" title="For Games, this measures the daily average number of subscribers that are subscribed to streamers of a game or category during the selected time period. For streamers this measures the total number of subscribers for the streamers channel.">
              <QuestionCircleOutlined />
            </Tooltip>
            {locale === 'EN' ? 'Subscribers' : '游戏粉丝数'}
          </div>
          {clubStreamers && clubStreamers.amountDatas ? <div className={`total ${search.cate === 'avgFans' ? 'total_number' : null}`}>{NumericConversion(clubStreamers.amountDatas[0].avgFans)}</div> : null}
        </div>,
        width: '10%',
        key: 'avgFans',
        dataIndex: 'avgFans',
        align: 'right',
        render: (record, row, index) => {
          return (
            <div className="custom_txt" style={search.cate === 'avgFans' ? { color: '#F4791F' } : null}>{NumericConversion(record)}</div>
          )
        }
      },
      {
        title: <div onClick={() => { this.handleChangeSearch('sumGift', 0) }} className={`cate_header ${search.cate === 'sumGift' ? 'cate_selected' : null}`}>
          <div className="title_name">
            <Tooltip overlayClassName="tooltip" className="text_icon" title="This measures the cumulative value of tips and virtual gifts that streamers receive within the game, category or as an individual streamer. This is measured in RMB and converted to USD.">
              <QuestionCircleOutlined />
            </Tooltip>
            {locale === 'EN' ? 'Tips' : '游戏打赏收入'}
          </div>
          {clubStreamers && clubStreamers.amountDatas ? <div className={`total ${search.cate === 'sumGift' ? 'total_number' : null}`}>{locale === 'EN' ? NumericConversion(clubStreamers.amountDatas[0].USsumGift) : NumericConversion(clubStreamers.amountDatas[0].sumGift)}</div> : null}
        </div>,
        key: 'sumGift',
        dataIndex: locale === 'EN' ? 'USsumGift' : 'sumGift',
        width: '10%',
        align: 'right',
        render: (record, text) => {
          return (
            <div className="custom_txt" style={search.cate === 'sumGift' ? { color: '#F4791F' } : null}>{NumericConversion(record)}</div>
          )
        }
      },
      {
        title: <div onClick={() => { this.handleChangeSearch('avgHot', 0) }} className={`cate_header ${search.cate === 'avgHot' ? 'cate_selected' : null}`}>
          <div className="title_name">
            <Tooltip overlayClassName="tooltip" className="text_icon" title="An index based on the daily average value of three streaming platforms’ own algorithms, providing a rating of the games number of viewers, hours streamed, and number of virtual gifts or tips donated.">
              <QuestionCircleOutlined />
            </Tooltip>
            {locale === 'EN' ? 'Heat Index' : '游戏热力值'}
          </div>
          {clubStreamers && clubStreamers.amountDatas ? <div className={`total ${search.cate === 'avgHot' ? 'total_number' : null}`}>{NumericConversion(clubStreamers.amountDatas[0].avgHot)}</div> : null}
        </div>,
        key: 'avgHot',
        width: '8%',
        dataIndex: 'avgHot',
        align: 'right',
        render: (record, text) => {
          return (
            <div className="custom_txt" style={search.cate === 'avgHot' ? { color: '#F4791F' } : null}>{NumericConversion(record)}</div>
          )
        }
      },
      {
        title: <div onClick={() => { this.handleChangeSearch('sumNiko', 0) }} className={`cate_header ${search.cate === 'sumNiko' ? 'cate_selected' : null}`}>
          <div className="title_name">
            <Tooltip overlayClassName="tooltip" className="text_icon" title="A weighted index estimated by Niko based on an algorithm approximating the number of active viewers of each streamer, game or category. This is a cumulative figure for the period selected.">
              <QuestionCircleOutlined />
            </Tooltip>
            {locale === 'EN' ? 'Niko Index' : 'Niko指数'}
          </div>
          {clubStreamers && clubStreamers.amountDatas ? <div className={`total ${search.cate === 'sumNiko' ? 'total_number' : null}`}>{NumericConversion(clubStreamers.amountDatas[0].sumNiko)}</div> : null}
        </div>,
        key: 'sumNiko',
        dataIndex: 'sumNiko',
        width: '8%',
        align: 'right',
        render: (record, text) => {
          return (
            <div className="custom_txt" style={search.cate === 'sumNiko' ? { color: '#F4791F' } : null}>{NumericConversion(record)}</div>
          )
        }
      },
      {
        title: <div onClick={() => { this.handleChangeSearch('sumLenth', 0) }} className={`cate_header ${search.cate === 'sumLenth' ? 'cate_selected' : null}`}>
          <div className="title_name">
            <Tooltip overlayClassName="tooltip" className="text_icon" title="This measures the cumulative number of hours streamed for the game, category or individual streamer across the time period selected.">
              <QuestionCircleOutlined />
            </Tooltip>
            {locale === 'EN' ? 'Hours' : '主播时长'}
          </div>
          {clubStreamers && clubStreamers.amountDatas ? <div className={`total ${search.cate === 'sumLenth' ? 'total_number' : null}`}>{NumericConversion(clubStreamers.amountDatas[0].sumLenth)}</div> : null}
        </div>,
        key: 'sumLenth',
        dataIndex: 'sumLenth',
        width: '8%',
        align: 'right',
        render: (record, row, index) => {
          return (
            <div className="custom_txt" style={search.cate === 'sumLenth' ? { color: '#F4791F' } : null}>{NumericConversion(record)}</div>
          )
        }
      },
      {
        title: <div className={'cate_header'}>Follow</div>,
        key: 'action',
        width: '3%',
        align: 'center',
        fixed: 'right',
        render: (text, record, index) => {
          return (
            <div
              className="followedIcon"
              onClick={() => { this.handleFollowed(record) }}
            >
              <img src={record.isLike ? require('../../assets/common/followed.png') : require('../../assets/common/unFollowed.png')} />
            </div>
          )
        }
      }
    ]
    return (
      <Table
        bordered={true}
        loading={!spin && tableLoading}
        dataSource={(clubStreamers || {}).data || []}
        columns={columns}
        pagination={pagination}
        rowKey={record => record.id}
        onChange={(pagination, filters, sorter) => {
          this.props.dispatch({
            type: 'streamerClubs/apiGetClubStreamers',
            payload: {
              page: pagination.current, perPage: pagination.pageSize, name, ...search,
              startDate: gameDate[0] || null,
              endDate: gameDate[1] || null
            }
          })
        }}
      />
    )
  }

  //格式化图表数据
  formatData = (datas, locale) => {
    const { catalogSelected } = this.state
    let xAxis = []
    let dData = []
    let timeFormat = _.groupBy(datas, 'createdAt')
    if (catalogSelected[0].value !== 'sumGift') {
      for (let key in timeFormat) {
        xAxis.push(key)
        timeFormat[key].forEach((vo, index) => {
          dData.push(vo[catalogSelected[0].value])
        })
      }
    } else {
      for (let key in timeFormat) {
        xAxis.push(key)
        timeFormat[key].forEach((vo, index) => {
          dData.push(locale === 'EN' ? vo.USsumGift : vo[catalogSelected[0].value])
        })
      }
    }
    this.setState({ xAxis: xAxis.concat(), dData: dData.concat() })
  }
  renderECharts = () => {
    const { xAxis, dData } = this.state
    let { clubDetails } = this.props
    const options = {
      tooltip: {
        trigger: 'axis'
      },
      legend: {
        data: [clubDetails && clubDetails.club],
        left: 'center',
        icon: 'roundRect'
      },
      grid: {
        left: '2%',
        right: '2%',
        bottom: '0%',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: xAxis,
        axisLabel: {
          interval: 0,
          rotate: 40
        },
      },
      yAxis: {
        type: 'value'
      },
      series: [
        {
          name: clubDetails && clubDetails.club,
          type: 'line',
          // stack: '总量',
          data: dData
        },
      ]
    }
    return (
      <ReactEcharts
        option={options}
        notMerge={true}
        lazyUpdate={true}
        lazyLoad={true}
        theme="light"
      />
    )
  }

  render() {
    const { catalog, catalogSelected, timeValue, gameDate } = this.state
    const handleGameDate = (time, dateString) => {
      if (time) {
        gameDate[0] = moment(time[0]).format('YYYY-MM-DD')
        gameDate[1] = moment(time[1]).format('YYYY-MM-DD')
        this.setState({ gameDate: gameDate.concat() })
      }
    }
    const { spin, clubDetails, locale } = this.props
    return (
      <BasicLayout selectedKeys={['/streamerClubs']} menu2Name={'Streamer Clubs'}>
        <Spin spinning={spin}>
          <div className="details_container">
            {clubDetails && (
              <Row span={24} className="top_card">
                <Col style={{ marginLeft: 20 }} span={18}>
                  <div className="logo">
                    <img src={clubDetails.platforms && clubDetails.platforms[0] && clubDetails.platforms[0].icon} />
                  </div>
                  <div className="infoBox">
                    <div className="name">{clubDetails.enName}</div>
                    <div className="name">{clubDetails.club}</div>
                  </div>
                </Col>
                <Col span={5} className="platform" >
                  <div className="title" style={{ fontWeight: 15, marginBottom: 18 }}>Platform:</div>
                  {clubDetails.platforms && (
                    clubDetails.platforms.map((vo, index) => {
                      return (
                        <Space
                          key={index}
                          direction={'horizontal'}
                          style={{ fontSize: 15, marginRight: 15 }}
                        >
                          <div className='text_Game' style={{ marginRight: 10 }}>{vo.platform}</div>
                          <img alt="img" style={{ width: 60 }} src={require(`../../assets/icon/${vo.platform}.png`)} />
                        </Space>
                      )
                    })
                  )}
                </Col>
              </Row>
            )}
            {clubDetails && (
              <div style={{ padding: '12px 0' }}>
                <Row gutter={24}>
                  <Col span={8}>
                    <Card title={'Subscribers'} hoverable={true}>
                      <div className="cardValue">
                        <div className="radio"></div>
                        <span>{clubDetails.statdata && clubDetails.statdata.sumFans}</span>
                      </div>
                      <Space className="changeNum" style={{ marginTop: 12, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                        {clubDetails.statdata && clubDetails.statdata.fansChange > 0 ? (<CaretUpOutlined style={{ color: '#0ae653' }} />) :
                          (<CaretDownOutlined style={{ color: '#e02a33' }} />)}
                        <span>{clubDetails.statdata && clubDetails.statdata.fansChange}</span>
                      </Space>
                    </Card>
                  </Col>
                  <Col span={8}>
                    <Card title={'Heat Index'} hoverable={true}>
                      <div className="cardValue">
                        <div className="radio"></div>
                        <span>{clubDetails.statdata && clubDetails.statdata.sumViewer}</span>
                      </div>
                      <Space className="changeNum" style={{ marginTop: 12, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                        <div />
                        {clubDetails.statdata && clubDetails.statdata.viewerChange > 0 ? (<CaretUpOutlined style={{ color: '#0ae653' }} />) :
                          (<CaretDownOutlined style={{ color: '#e02a33' }} />)}
                        <span>{clubDetails.statdata && clubDetails.statdata.viewerChange}</span>
                      </Space>
                    </Card>
                  </Col>
                  <Col span={8}>
                    <Card title={'Niko Index'} hoverable={true}>
                      <div className="cardValue">
                        <div className="radio"></div>
                        <span>{clubDetails.statdata && clubDetails.statdata.NikoData}</span>
                      </div>
                      <Space className="changeNum" style={{ marginTop: 12, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                        {clubDetails.statdata && clubDetails.statdata.NikoDataChange > 0 ? (<CaretUpOutlined style={{ color: '#0ae653' }} />) :
                          (<CaretDownOutlined style={{ color: '#e02a33' }} />)}
                        <span>{parseInt(clubDetails.statdata && clubDetails.statdata.NikoDataChange) || null}</span>
                      </Space>
                    </Card>
                  </Col>
                </Row>
              </div>
            )}
            <div className="steamer_card">
              <div className="top_search">
                <Row>
                  {[{
                    nameEN: 'Time Period',
                    nameCN: '时间段',
                    list: [
                      { nameEN: 'Last week', nameCN: '近一周', value: '0' },
                      { nameEN: 'Last month', nameCN: '近一月', value: '1' },
                      { nameEN: 'Customize', nameCN: '自定义', value: '2' }
                    ]
                  }].map((vo, index) => {
                    return (
                      <Col key={index} span={24}>
                        <div className="item_line">
                          <div className="title">{locale === 'EN' ? vo.nameEN : vo.nameCN}</div>
                          <div className="button_content">
                            {vo.list.map((item, key) => {
                              return (
                                <div key={key}
                                  className={`button ${timeValue === item.value ? 'button_activity' : null}`}
                                  onClick={() => {
                                    this.setState({ timeValue: item.value })
                                    if (item.value == '0') {
                                      gameDate[0] = moment().subtract(8, 'days').format('YYYY-MM-DD')
                                      gameDate[1] = moment().subtract(1, 'days').format('YYYY-MM-DD')
                                      this.setState({ gameDate: gameDate.concat() })
                                    } else if (item.value === '1') {
                                      gameDate[0] = moment().subtract(31, 'days').format('YYYY-MM-DD')
                                      gameDate[1] = moment().subtract(1, 'days').format('YYYY-MM-DD')
                                      this.setState({ gameDate: gameDate.concat() })
                                    }
                                  }}
                                >{locale === 'EN' ? item.nameEN : (item.nameCN ? item.nameCN : item.nameEN)}</div>
                              )
                            })}
                            {
                              timeValue === '2'
                                ?
                                <RangePicker
                                  disabledDate={disabledDate}
                                  onChange={handleGameDate}
                                  style={{ padding: '2px 14px 2px', margin: '0 13px 13px 0' }}
                                />
                                :
                                null
                            }
                          </div>
                        </div>
                      </Col>
                    )
                  })}
                </Row>
              </div>
              <div className="export" >
                <Space>
                  <div className="export_button" onClick={() => { this.handleExport() }}><ExportOutlined className="icon" />{locale === 'EN' ? 'Export Data' : '导出'}</div>
                </Space>
              </div>
              <div>{this.renderTable()}</div>
            </div>
            <div className="bottom_card">
              <div className="catalog_line">
                {catalog[0].list.map((vo, index) => {
                  return (
                    <div key={index} className="catalog_item hoverDiv" onClick={() => { this.handleSelectCatalog(vo.value, 0) }}>
                      <img alt="img" className="icon" src={catalogSelected[0].value ? vo.icon_selected : vo.icon} />
                      <span className="title" style={vo.value === catalogSelected[0].value ? { color: '#2C67A4' } : null}>{locale === 'EN' ? vo.nameEN : vo.nameCN}</span>
                      {vo.value === catalogSelected[0].value ? <div className="activity_line"></div> : null}
                    </div>
                  )
                })}
              </div>
              <div className="search_content">
                <div className="search">
                  <Row>
                    {[catalog[1]].map((vo, index) => {
                      return (
                        <Col key={index} span={index === 0 || index === 3 || index === 4 || index === 5 ? 24 : 12}>
                          <div className="item_line">
                            <div className="title">{locale === 'EN' ? vo.nameEN : vo.nameCN}</div>
                            {vo.list.map((item, key) => {
                              return (
                                <div key={key}
                                  className={`button ${catalogSelected[1].value === item.value ? 'button_activity' : null}`}
                                  onClick={() => { this.handleSelectCatalog(item.value, 1) }}
                                >{locale === 'EN' ? item.nameEN : item.nameCN}</div>
                              )
                            })}
                            {
                              catalogSelected[1].value === '2'
                                ?
                                <RangePicker
                                  disabledDate={disabledDate}
                                  onChange={this.handleChangeDate}
                                  style={{ padding: '2px 14px 2px' }}
                                />
                                :
                                null
                            }
                          </div>
                        </Col>
                      )
                    })}
                  </Row>
                </div>
                {/* <div className="export_button" onClick={()=>{ this.handleExport()}}><ExportOutlined className="icon"/>{locale==='EN' ? 'Export Data' : '导出'}</div> */}
              </div>
              <div>{this.renderECharts()}</div>
            </div>
          </div>
        </Spin>
      </BasicLayout>
    )
  }
}
export default connect(({ streamerClubs, user, streamers }) => ({
  clubDetails: streamerClubs.clubDetails,
  spin: streamerClubs.spin,
  clubStreamers: streamerClubs.clubStreamers,
  clubChart: streamerClubs.clubChart,
  tableLoading: streamerClubs.tableLoading,
  locale: user.locale,
  userInfo: user.userInfo,
  flag: streamers.flag
}))(ClubDetails)