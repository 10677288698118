import React, { Component } from 'react'
import { connect } from 'dva'
import { routerRedux } from 'dva/router'
import './styles.less'
import { Tooltip } from 'antd'
import { RightCircleOutlined, CaretDownOutlined, QuestionCircleOutlined } from '@ant-design/icons'

import BasicLayout from '../../components/BasicLayout'

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    //------------------------生命周期-----------------------//
    componentDidMount() {
        this.props.dispatch({ type: 'home/apiGetAnchorList' })
    }

    //------------------------事件-----------------------//
    handleSearch = (e) => {
        const { value } = e.target
        if(value){
            this.props.dispatch(routerRedux.push(`/home/searchResult`))
        }
    }
    handleJumpToGoodsList = () => {
        const { userInfo } = this.props
        if(!userInfo || !userInfo.id){
            return
        }
        document.documentElement.scrollTop = 0
        this.props.dispatch(routerRedux.push('/gameList'))
    }
    handleJumpToGameDetails = (gameID) => {
        if(gameID){
            const { userInfo } = this.props
            if( !userInfo || !userInfo.id ){
                this.props.dispatch(routerRedux.push('/login'))
            }else{
                this.props.dispatch(routerRedux.push(`/gameList/gameDetails/${gameID}`))
            }
        }
    }
    //------------------------渲染-----------------------//
    render() {
        const { cateList, locale } = this.props
        return (
          <BasicLayout selectedKeys={['/home']}>
              <div className="home_container">
                  <div className="banner_search">
                      <div className="search">
                          <div className="title">Streaming Tracker</div>
                          {/* {userInfo && userInfo.id ? <div className="searchInput">
                              <Input className="input"
                                     placeholder={locale==='EN' ? 'Game title' : '请输入游戏标题'}
                                     onPressEnter={this.handleSearch}
                              />
                              <div className="search_icon"><SearchOutlined /></div>
                          </div> : null} */}
                      </div>
                  </div>
                  <div className="main_content">
                      <div className="card_list">
                          {cateList.map((record, key)=>{
                              return (
                                <div className="card" key={key}>
                                    <div className="top_line">
                                        <div>
                                            <span className="name">
                                                <Tooltip overlayClassName="tooltip" title={record.tips}>
                                                     <QuestionCircleOutlined style={{marginRight: '10px'}}/>
                                                </Tooltip>
                                                {locale==='EN' ? record.nameEN : record.nameCN}
                                            </span>
                                            <span className="arrow hoverDiv" onClick={()=>{this.handleJumpToGoodsList()}}>{locale==='EN' ? 'More' : '更多'}<RightCircleOutlined className="icon"/></span>
                                        </div>
                                    </div>
                                    <div className="list">
                                        {record.list.map((vo, index)=>{
                                            return (
                                              <div key={index} className="item" onClick={()=>{this.handleJumpToGameDetails(vo.gameID)}}>
                                                  <div className="main">
                                                      <div className="left_info">
                                                          <div className="logo">
                                                              {vo.gameInfo&&vo.gameInfo.link ? <img src={vo.gameInfo.link} alt={'icon'}/> : null}
                                                          </div>
                                                          {vo.gameInfo ? <div className="text">{locale==='EN' ? vo.gameInfo.gameEn : vo.gameInfo.game}</div> : null}
                                                      </div>
                                                      {
                                                          vo.compare!==0 ? <div className="right_compare">
                                                              <CaretDownOutlined className={`compareNumber ${vo.compare>0 ? 'upIcon' : null}`} />
                                                              <span className={`compareNumber span ${vo.compare>0 ? 'upNumber' : null}`}>{Math.abs(vo.compare)}</span>
                                                          </div> : null
                                                      }
                                                  </div>
                                                  <div className="ranking">
                                                      {/* <div className="topTxt">{vo.rank}</div> */}
                                                      <div className="topTxt">{record.list.indexOf(vo)+1}</div>
                                                  </div>
                                              </div>
                                            )
                                        })}
                                    </div>
                                </div>
                              )
                          })}
                      </div>
                  </div>
              </div>
          </BasicLayout>
        )
    }

}
export default connect(({ home, user })=>({
    cateList: home.cateList,
    userInfo: user.userInfo,
    locale: user.locale
}))(Index)