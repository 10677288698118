import React, { Component } from 'react'
import { connect } from 'dva'
import { Table, Row, Col, Tooltip, DatePicker } from 'antd'
import { ExportOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import './styles.less'
import moment from 'moment'
import Config from '../../config/Config'
import { getToken } from '../../utils/authority'
import qs from 'query-string'

import BasicLayout from '../../components/BasicLayout'
import { routerRedux } from 'dva/router'


class GameList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      catalog: [
        {
          nameEN: 'Streaming Platform',
          nameCN: '流媒体网站',
          list: [
            { nameEN: 'All', value: null },
            { nameEN: 'DouYu', value: '103' },
            { nameEN: 'HuYa', value: '102' },
            { nameEN: 'Bilibili', value: '101' },
          ]
        },
        {
          nameEN: 'Game Publisher',
          nameCN: '经营者',
          list: [
            { nameEN: 'All', value: null },
            { nameEN: 'Tencent', value: 'Tencent' },
            { nameEN: 'NetEase', value: 'Netease' },
            { nameEN: 'Steam', value: 'Steam' },
            {nameEN: 'Mihoyo Game', value: 'Mihoyo Game'},
            {nameEN: 'EA', value: 'EA'},
            {nameEN: 'Ubisoft', value: 'Ubisoft'},
            {nameEN: 'Perfect World', value: 'Perfect World'},
            {nameEN: 'Nexon', value: 'Nexon'},
            {nameEN: 'Blizzard', value: 'Blizzard'},
            {nameEN: 'SCE', value: 'SCE'},
            {nameEN: 'Nintendo', value: 'Nintendo'},
            {nameEN: 'Shengqu', value: 'Shengqu'},
            {nameEN: '37 Game', value: '37 Game'},
            {nameEN: '4399 Game', value: '4399 Game'},
            {nameEN: 'Leiting Game', value: 'Leiting Game'},
            {nameEN: 'Activison', value: 'Activison'},
            {nameEN: 'Take-Two', value: 'Take-Two'},
            {nameEN: 'Bilibili', value: 'Bilibili'},
            { nameEN: 'Other', value: 'other' },
          ]
        },
        {
          nameEN: 'Game Segment',
          nameCN: '平台',
          list: [
            { nameEN: 'All', value: null },
            { nameEN: 'PC', value: 'PC' },
            { nameEN: 'Mobile', value: 'Mobile' },
            { nameEN: 'Console', value: 'Console' },
            // {nameEN: 'Non-game', value: 'Non-game'}
          ]
        },
        {
          nameEN: 'Genre',
          nameCN: '类型',
          list: [
            { nameEN: 'All', value: null },
            { nameEN: 'MOBA', value: 'MOBA' },
            { nameEN: 'Battle Royale', value: 'Battle Royale' },
            { nameEN: 'RPG', value: 'RPG' },
            // {nameEN: 'Non-game', value: 'Non-game'},
            { nameEN: 'FPS/TPS', value: 'FPS/TPS' },
            { nameEN: 'Auto Chess', value: 'Auto Chess' },
            // {nameEN: 'Series games', value: 'Series games'},
            { nameEN: 'Racing', value: 'Racing' },
            { nameEN: 'Sandbox', value: 'Sandbox' },
            { nameEN: 'Trading Card', value: 'Trading Card' },
            { nameEN: 'Strategy', value: 'Strategy' },
            { nameEN: 'RTS', value: 'RTS' },
            { nameEN: 'Action', value: 'Action' },
            { nameEN: 'Sporting', value: 'Sporting' },
            { nameEN: 'Horror', value: 'Horror game' },
            { nameEN: 'Puzzle', value: 'Puzzle' },
            { nameEN: 'Simulation', value: 'Simulation' },
            { nameEN: 'Adventure', value: 'Adventure' },
            { nameEN: 'Poker & Mahjong', value: 'Poker & Mahjong' },
            { nameEN: 'Running', value: 'Running' },
            { nameEN: 'Casual', value: 'Casual' },
            { nameEN: 'Idle', value: 'Idle' },
            { nameEN: 'Dancing', value: 'Dancing' },
            { nameEN: 'Roguelike', value: 'Roguelike' },
          ]
        },
        {
          nameEN: 'Developers',
          nameCN: '发展商',
          list: [
            {nameEN: 'All', value: null},
            {nameEN: 'Tencent', value: 615},
            {nameEN: 'NetEase', value: 617},
            {nameEN: 'Steam', value: 616},
            {nameEN: 'Mihoyo Game', value: 627},
            {nameEN: 'EA', value: 622},
            {nameEN: 'Ubisoft', value: 690},
            {nameEN: 'Perfect World', value: 618},
            {nameEN: 'Nexon', value: 766},
            {nameEN: 'Blizzard', value: 621},
            {nameEN: 'SCE', value: 653},
            {nameEN: 'Nintendo', value: 647},
            {nameEN: 'Shengqu', value: 623},
            {nameEN: '37 Game', value: 686},
            {nameEN: '4399 Game', value: 752},
            {nameEN: 'Leiting Game', value: 691},
            {nameEN: 'Activison', value: 1065},
            {nameEN: 'Take-Two', value: 790},
            {nameEN: 'Bilibili', value: 631},
            {nameEN: 'Other', value: 'other'},
          ]
        },
        {
          nameEN: 'Time Period',
          nameCN: '时间段',
          list: [
            { nameEN: 'Day', nameCN: '昨天', value: '0' },
            { nameEN: 'Week', nameCN: '周', value: '1' },
            { nameEN: 'Month', nameCN: '月度', value: '2' },
            { nameEN: 'Quarter', nameCN: '季度', value: '3' },
            { nameEN: 'Year', nameCN: '年度', value: '4' }
          ]
        },
      ],
      catalogSelected: [
        { value: [] },
        { value: [] },
        { value: [] },
        { value: [] },
        { value: [] },
        { value: '0' }
      ],
      search: {
        dateType: 0,
        date: moment().format('YYYY-MM-DD'),
      },
      dateList: [moment().subtract(2, 'days').format('YYYY-MM-DD'), moment().subtract(1, 'days').format('YYYY-MM-DD')],
      dateIndex: 0,
      showMore: false,
    }
  }

  componentDidMount() {
    const { search } = this.state
    this.props.dispatch({
      type: 'gameList/apiGetAllMetrics',
      payload: {
        page: 1,
        perPage: 10,
        ...search
      }
    })
  }

  componentWillReceiveProps(nextProps, nextContext) {
  }

  handleDateChange = async (dateIndex) => {
    dateIndex = parseInt(dateIndex)
    let date
    switch (dateIndex) {
      case 0:
        return date = moment().format('YYYY-MM-DD')
      case 1:
        return date = moment().format('YYYY-WW')
      case 2:
        return date = moment().format('YYYY-MM')
      case 3:
        return date = moment().format('YYYY-') + "Q" + moment().format('Q')
      case 4:
        return date = moment().format('YYYY')
      default:
        break;
    }
  }
  handleLastDateChange = async (dateIndex) => {
    dateIndex = parseInt(dateIndex)
    let date
    switch (dateIndex) {
      case 0:
        return date = moment().subtract(1, 'days').format('YYYY-MM-DD')
      case 1:
        return date = moment().subtract(1, 'weeks').format('YYYY-WW')
      case 2:
        return date = moment().subtract(1, 'months').format('YYYY-MM')
      case 3:
        return date = moment().format('YYYY-') + "Q" + moment().subtract(1, 'quarters').format('Q')
      case 4:
        return date = moment().subtract(1, 'years').format('YYYY')
      default:
        break;
    }
  }
  async UNSAFE_componentWillUpdate(nextProps, nextState, nextContext) {
    const { catalogSelected } = this.state
    if (catalogSelected !== nextState.catalogSelected && nextState.catalogSelected) {
      let payload = {}
      payload.platformID = nextState.catalogSelected[0].value.length !== 0 ? nextState.catalogSelected[0].value.map((vo) => { return vo }).join(',') : null
      payload.Operator = nextState.catalogSelected[1].value.length !== 0 ? nextState.catalogSelected[1].value.map((vo) => { return vo }).join(',') : null
      payload.Platform = nextState.catalogSelected[2].value.length !== 0 ? nextState.catalogSelected[2].value.map((vo) => { return vo }).join(',') : null
      payload.gameGenre = nextState.catalogSelected[3].value.length !== 0 ? nextState.catalogSelected[3].value.map((vo) => { return vo }).join(',') : null
      payload.DeveloperID = nextState.catalogSelected[4].value.length !== 0 ? nextState.catalogSelected[4].value.map((vo) => { return vo }).join(',') : null
      payload.dateType = nextState.catalogSelected[5].value
      let datecount = parseInt(nextState.catalogSelected[5].value)
      payload.date = await this.handleDateChange(datecount)
      payload.lastDate = await this.handleLastDateChange(datecount)
      switch (datecount) {
        case 0:
          payload.lastDate = moment(payload.date).subtract(2, 'days').format('YYYY-MM-DD')
          payload.date = moment(payload.date).subtract(1, 'days').format('YYYY-MM-DD')
          break;
        case 1:
          payload.date = payload.date + 'W'
          const dateStr = payload.date;
          const lastDigit = parseInt(dateStr[6]) || 0;
          const newDigit = lastDigit > 0 ? lastDigit - 1 : 0;
          const newDateStr = dateStr.slice(0, 6) + newDigit;
          payload.lastDate = newDateStr + 'W'
          break;
        case 2:
          payload.date = moment(payload.date).format('YYYY-MM')
          payload.lastDate = moment(payload.date).subtract(1, 'months').format('YYYY-MM')
          break;
        case 3:
          payload.date = payload.date
          const dateStr1 = payload.date;
          const lastDigit1 = parseInt(dateStr1[6]) || 0;
          const newDigit1 = lastDigit1 > 0 ? lastDigit1 - 1 : 0;
          const newDateStr1 = dateStr1.slice(0, 6) + newDigit1;
          payload.lastDate = newDateStr1
          break;
        case 4:
          payload.date = moment(payload.date).format('YYYY')
          payload.lastDate = moment(payload.date).subtract(1, 'years').format('YYYY')
          break;
        default:
          payload.lastDate = moment().subtract(2, 'days').format('YYYY-MM-DD')
          payload.date = moment().subtract(1, 'days').format('YYYY-MM-DD')
          break;
      }
      this.setState({
        search: payload,
        dateList: [payload.lastDate, payload.date].concat(),
        dateIndex: parseInt(payload.dateType)
      })
      this.props.dispatch({
        type: 'gameList/apiGetAllMetrics',
        payload: { ...payload }
      })
    }
  }

  handleSelectCatalog = (item, index) => {
    const { catalogSelected } = this.state
    if (index === 5 || index === catalogSelected.length - 1) {
      if (index === 2 && item === 'other') {
        this.props.dispatch(routerRedux.push('/company'))
        return
      }
      if (catalogSelected[index].value === item) {
        catalogSelected[index].value = null
        return
      }
      catalogSelected[index].value = item
    } else {
      if (index === 5 && item === 'other') {
        this.props.dispatch(routerRedux.push('/developer'))
        return
      }
      let key = catalogSelected[index].value.indexOf(item)
      if (key < 0) {
        if (!item) {
          catalogSelected[index].value = []
        } else {
          catalogSelected[index].value.push(item)
        }
      } else {
        catalogSelected[index].value.splice(key, 1)
      }
    }
    this.setState({ catalogSelected: catalogSelected.concat() })
  }
  handleTableChange = (pagination, filters, sorter) => {
    const { search } = this.state
    this.props.dispatch({
      type: 'gameList/apiGetAllMetrics',
      payload: { page: pagination.current, perPage: pagination.pageSize, ...search }
    })
  }
  disabledDate(current) {
    if (current) {
      return current > moment().endOf('day') || current<moment('2020-07').endOf('day');
    }
  }
  renderTable = () => {
    const { search, dateList } = this.state
    const { metricslist, page, perPage, locale, pageLoading } = this.props
    const pagination = {
      current: parseInt(page) || 0,
      total: (metricslist || {}).total || 0,
      defaultCurrent: 1,
      defaultPageSize: parseInt(perPage) || 0,
      pageSize: perPage,
      showQuickJumper: true,
      position: 'bottom',
    }
    const columns = [
      {
        title: <div className={'cate_header'}>{locale === 'EN' ? 'Date' : '日期'}</div>,
        dataIndex: 'date',
        key: 'date',
        render: (record, row, index) => {
          return (
            <div className="custom_txt">{record}</div>
          )
        }
      },
      {
        title: <div id={'header'} className={`cate_header cate_selected`}>
          <div className="title_name">
            <Tooltip overlayClassName="tooltip" className="text_icon" title="This measures the total cumulative number of streamers for the game or category on a daily cumulative basis for the time period selected.">
              <QuestionCircleOutlined />
            </Tooltip>
            {locale === 'EN' ? 'Streamers' : '主播数量'}
          </div>
        </div>,
        key: 'sumAnchor',
        dataIndex: 'sumAnchor',
        width: '10%',
        align: 'right',
        render: (record, row, index) => {
          return (
            <div className="custom_txt" style={{ color: '#F4791F' }}>{record && (record).toLocaleString()}</div>
          )
        }
      },
      {
        title: <div className={`cate_header cate_selected`}>
          <div className="title_name">
            <Tooltip overlayClassName="tooltip" className="text_icon" title="This measures the cumulative number of hours streamed for the game, category or individual streamer across the time period selected.">
              <QuestionCircleOutlined />
            </Tooltip>
            {locale === 'EN' ? 'Streamed Hours' : '主播时长'}
          </div>
        </div>,
        key: 'sumLenth',
        dataIndex: 'sumLenth',
        width: '12.4%',
        align: 'right',
        render: (record, row, index) => {
          return (
            <div className="custom_txt" style={{ color: '#F4791F' }}>{record && (record).toLocaleString()}</div>
          )
        }
      },
      {
        title: <div className={`cate_header cate_selected`}>
          <div className="title_name">
            <Tooltip overlayClassName="tooltip" className="text_icon" title="For Games, this measures the daily average number of subscribers that are subscribed to streamers of a game or category during the selected time period. For streamers this measures the total number of subscribers for the streamers channel.">
              <QuestionCircleOutlined />
            </Tooltip>
            {locale === 'EN' ? 'Subscribers' : '游戏粉丝数'}
          </div>
        </div>,
        key: 'sumFans',
        dataIndex: 'sumFans',
        width: '12.4%',
        align: 'right',
        render: (record, row, index) => {
          return (
            <div className="custom_txt" style={{ color: '#F4791F' }}>{record && (record).toLocaleString()}</div>
          )
        }
      },
      {
        title: <div className={`cate_header cate_selected`}>
          <div className="title_name">
            <Tooltip overlayClassName="tooltip" className="text_icon" title="This measures the cumulative value of tips and virtual gifts that streamers receive within the game, category or as an individual streamer. This is measured in RMB and converted to USD.">
              <QuestionCircleOutlined />
            </Tooltip>
            {locale === 'EN' ? 'Tips' : '游戏打赏收入'}
          </div>
        </div>,
        key: 'sumGift',
        dataIndex: 'sumGift',
        width: '10%',
        align: 'right',
        render: (record, text) => {
          return (
            <div className="custom_txt" style={{ color: '#F4791F' }}> {record && (record).toLocaleString()}</div>
          )
        }
      },
      {
        title: <div className={`cate_header cate_selected`}>
          <div className="title_name">
            <Tooltip overlayClassName="tooltip" className="text_icon" title="An index based on the daily average value of three streaming platforms’ own algorithms, providing a rating of the games number of viewers, hours streamed, and number of virtual gifts or tips donated.">
              <QuestionCircleOutlined />
            </Tooltip>
            {locale === 'EN' ? 'Heat Index' : '游戏热力值'}
          </div>
        </div>,
        key: 'sumHot',
        dataIndex: 'sumHot',
        width: '12.4%',
        align: 'right',
        render: (record, text) => {
          return (
            <div className="custom_txt" style={{ color: '#F4791F' }}>{record && (record).toLocaleString()}</div>
          )
        }
      },
      {
        title: <div className={`cate_header cate_selected`}>
          <div className="title_name">
            <Tooltip overlayClassName="tooltip" className="text_icon" title="A weighted index estimated by Niko based on an algorithm approximating the number of active viewers of each streamer, game or category. This is a cumulative figure for the period selected.">
              <QuestionCircleOutlined />
            </Tooltip>
            {locale === 'EN' ? 'Niko Index' : 'Niko指数'}
          </div>
        </div>,
        key: 'sumNiko',
        dataIndex: 'sumNiko',
        width: '12.4%',
        align: 'right',
        render: (record, text) => {
          return (
            <div className="custom_txt" style={{ color: '#F4791F' }}>{record && (record).toLocaleString()}</div>
          )
        }
      },
      {
        title: <div className={'cate_header'}>{locale === 'EN' ? 'Total Games' : '游戏数量'}</div>,
        dataIndex: 'TotalGames',
        key: 'TotalGames',
        align: 'right',
        width: '10%',
        render: (record, row, index) => {
          return (
            <div className="custom_txt" style={{ color: '#F4791F' }}>{record && record.toLocaleString()}</div>
          )
        }
      },
    ]
    return (
      <Table
        loading={pageLoading}
        dataSource={(metricslist || {}).data || []}
        columns={columns}
        rowKey={record => record.date}
        bordered={true}
        pagination={pagination}
        onChange={this.handleTableChange}
      />
    )
  }
  handleReset = () => {
    this.setState({
      catalogSelected: [
        { value: [] },
        { value: [] },
        { value: [] },
        { value: [] },
        { value: [] },
        { value: '0' },
      ],
      search: {
      },
      showMore: false,
    })
  }

  handleExport = () => {
    const { userInfo } = this.props
    if (userInfo && userInfo.status !== 'test' && userInfo.status !== 'off') {
      const { search } = this.state
      for (let index in search) {
        if (!search[index]) {
          delete search[index]
        }
      }
      window.open(`${Config.SERVER_HOME}downloadAllMetrics?WebAuth=${getToken()}&${qs.stringify(search)}`)
      this.props.dispatch({
        type: 'user/apiPostDownloadFiles',
        payload: { userID: parseInt(userInfo.id), fileID: 11 }
      })
    } else {
      this.props.dispatch({ type: 'user/setState', payload: { forbidDownload: true } })
    }
  }

  render() {
    const { locale } = this.props
    const { catalog, catalogSelected, showMore } = this.state
    return (
      <BasicLayout selectedKeys={['/allmetrics']}>
        <div className="top_list">
          <div className="top_search">
            <Row>
              {catalog.map((vo, index) => {
                return (
                  <Col key={index} span={24}>
                    <div className="item_line">
                      <div className="title">{locale === 'EN' ? vo.nameEN : vo.nameCN}</div>
                      <div className="button_content">
                        {vo.list.map((item, key) => {
                          if (!showMore && key < 7  ) {
                            return (
                              <div key={key} style={{ cursor: 'pointer' }}
                                className={`button ${(index === 5 || index === catalogSelected.length - 1) ? (catalogSelected[index].value === item.value ? 'button_activity' : null) : (catalogSelected[index].value.indexOf(item.value) >= 0 || (!catalogSelected[index].value[0] && !item.value) ? 'button_activity' : null)}`}
                                onClick={() => { this.handleSelectCatalog(item.value, index) }}
                              >{locale === 'EN' ? item.nameEN : (item.nameCN ? item.nameCN : item.nameEN)}</div>
                            )
                          } else {
                            return (
                              (showMore  ) ? <div key={key} style={{ cursor: 'pointer' }}
                                className={`button ${(index === 5 || index === catalogSelected.length - 1) ? (catalogSelected[index].value === item.value ? 'button_activity' : null) : (catalogSelected[index].value.indexOf(item.value) >= 0 || (!catalogSelected[index].value[0] && !item.value) ? 'button_activity' : null)}`}
                                onClick={() => { this.handleSelectCatalog(item.value, index) }}
                              >{locale === 'EN' ? item.nameEN : (item.nameCN ? item.nameCN : item.nameEN)}</div> : null
                            )
                          }
                        })}
                        {vo.list.length >19 ? <div className={'button more'}>
                          <span onClick={() => { this.setState({ showMore: !this.state.showMore }) }}>
                            {!showMore ? (locale === 'EN' ? 'Open' : '展开') : (locale === 'EN' ? 'Take back' : '收起')}
                          </span>
                        </div> : null}
                      </div>
                    </div>
                  </Col>
                )
              })}
            </Row>
          </div>
          <div className="export">
            <div style={{ marginRight: 15, cursor: 'pointer' }} className="reset_button" onClick={() => { this.handleReset() }}>{locale === 'EN' ? 'Reset' : '重置'}</div>
            <div style={{ cursor: 'pointer' }} className="export_button" onClick={() => { this.handleExport() }}><ExportOutlined className="icon" />{locale === 'EN' ? 'Export Data' : '导出'}</div>
          </div>
          {this.renderTable()}
        </div>
      </BasicLayout>
    )
  }
}

export default connect(({ gameList, user }) => ({
  metricslist: gameList.metricslist,
  page: gameList.page,
  perPage: gameList.perPage,
  pageLoading: gameList.pageLoading,
  userInfo: user.userInfo,
  locale: user.locale
}))(GameList)